import axios from "axios";

export default function AfficheCommandeCommercial(){
    const urlShowCommandeCommercial= `${process.env.REACT_APP_PHP_APP_URL}/admin/commercial/commande`;
    const GetCommandeCommercial=()=>{
        return (axios.get(urlShowCommandeCommercial));
    }
    return {
        GetCommandeCommercial
    }
}