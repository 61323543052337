import { Link } from "react-router-dom";
import BarDeretourSetting from "../../../components/BarDeretourSetting";
import Sidebare from "../Sidbare/Sidebare";

export default function ChnageMailAdmin(){
    return<>
    <Sidebare/>
    <div className="container mt-5">
    <BarDeretourSetting/>
      <div className="card-password">
<h5 className="">Veuillez changer Email ici <i className="bi bi-envelope"></i></h5>
<div className="row">

<div className="col">
    <span>Votre email actuel </span>

      <input type="text" className="form-control" />
    </div>
    <div className="col-4">
    <span>mot de passe</span>

      <input type="text"  className="form-control" />
    </div>
</div>
    <div className="col-7">
    <span>Nouveau Email</span>

      <input type="text"  name="prenom"  className="form-control" />
    </div>
    <br />
      <Link to={""} className="btn-changer">Valider</Link>
    <br />
      <Link to={""}>Contacter Nous </Link>
    </div>
    </div>
    </>
}