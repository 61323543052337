import React, { useEffect, useState } from 'react';
import Sidebare from "../Sidbare/Sidebare"
import PRODUIT1 from "../IMG/I-FLUX_200-768x768 1.png"
import profil from "../IMG/defaultProfil.png"
import "./CommercialInformation.css"
import { Link, useParams } from 'react-router-dom';
import GetGommercialById from '../../../Services/Admin/GetCommercialById';
import { ArticleInfo } from '../../../Modeles/ArticleModel';
import { ClientInfo } from '../../../Modeles/Client';
import GetClientlById from '../../../Services/Admin/GetClientById';
import AfficheArticleInMagasin from '../../../Services/Magasin/AfficheArticleInMagasin';
import AfficheArticlesCCInfo from '../../../Services/Magasin/AfficheArticlesCCInfo';
export interface ClientType{

  Commercial : ClientInfo[],
  messageErrosCommercial:string,
}
export interface ArticleType{
  Article : ArticleInfo[],
  messageErrosArticle:string,
}
export default function ClientInformation(){
  const { id,idm } = useParams();
  
  // -------------------------- affiche commercial by id --------------------

  const [stateCommercial, setstateCommercial] = useState<ClientType>({
    Commercial: [] as ClientInfo[],
    messageErrosCommercial: "Accune commande",
  });
  useEffect(()=>{
    setstateCommercial({...stateCommercial })
      GetClientlById(id,idm).GetClient()
        .then((res)=>setstateCommercial({...stateCommercial  , Commercial:res.data})
  
        )
        .catch(msg=>setstateCommercial({...stateCommercial  , messageErrosCommercial:msg.messageErrosCommercial}))
  },[]);
  const {Commercial , messageErrosCommercial} = stateCommercial

  // -----------------------------  affiche article -------------------------------
  const [stateArticle, setstateArticle] = useState<ArticleType>({
    Article: [] as ArticleInfo[],
    messageErrosArticle: "Accune commande",
  });
  useEffect(()=>{
    setstateArticle({...stateArticle })
    AfficheArticlesCCInfo(id).getArticle()
        .then((res)=>setstateArticle({...stateArticle  , Article:res.data})
  
        )
        .catch(msg=>setstateArticle({...stateArticle  , messageErrosArticle:msg.messageErrosArticle}))
  },[]);
  const {Article , messageErrosArticle} = stateArticle
  console.log(Commercial)
  const handleGoBack = () => {
    window.history.back();
};
    return <>

    <Sidebare/>
    <div className="container mt-3">
        <div className="barRetour">
      <span className="iconRetour">
        <i onClick={handleGoBack} className="bi bi-arrow-left-short"></i>
      </span>
    </div>
    </div>
    <div className="container-commercial-detail">
    <div className="card-commercial-detail">
    <div className="photo-info-commercial-detail">
        <img src={profil} />
    </div>
{Commercial && Commercial.length>0?Commercial.map(com=>(

    <h2 className="product-name-commercial-detail">{com.NomClient} {com.PrenomClient}</h2>
  )):""
}
  <h6 >Statut : <span>Actif <i className="actif bi bi-circle-fill"></i></span> </h6>
  <Link className='bloque' to={""}><h6 className="mb-5 ">bloqué <i className="bi bi-dash-circle"></i> </h6></Link>

</div>
{Commercial && Commercial.length>0?Commercial.map(com=>(
  <>
<div className="card2-commercial-detail">
    <div className="info-commercial-detail">
      
        <p><strong>Nom:</strong></p>  <span>{com.NomClient} {com.PrenomClient}</span>
        <hr />
        <p><strong>Email:</strong> </p> <span>{com.EmailClient}</span>
        <hr />
        <p><strong>Mot de passe:</strong></p>  <span>{com.PasswordClient}</span>
        <hr />
        <p><strong>Crédit Numéro:</strong></p>  <span>{com.Credit?com.Credit :"N/A"}</span>
        <hr />

        <p><strong>Mobile:</strong></p>  <span>{com.NumTele  ?com.NumTele :"N/A"}</span>
        <hr />
        <p><strong>Adress:</strong></p><span> {com.Ville ?com.Ville :"N/A"}</span>    
    </div>
</div>
<div className="card3-commercial-detail" >
    <div className="sold text-center mt-5">
      <div className="progresse">
        
          <div className="progress" style={{ width: '50%', backgroundColor: '#FFC400' }}>
          </div>
          <div className="solde">
            <h5>Solde de {com.NomClient}</h5>

          </div>
            <span> 2 500 MAD /  10 000 MAD</span>
          
        </div>
    </div>
  <button className='btn btn-danger mt-5'>Supprimer ce client </button>

</div>
</>
)):""
}
</div>   

    </>
}