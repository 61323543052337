import Sidebare from "../Sidbare/Sidebare";
import { Link, useParams } from "react-router-dom";
import E1 from "../IMG/3.png"
import profil from "../IMG/defaultProfil.png"
import E2 from "../IMG/e1.png"
import E3 from "../IMG/e2.png"
import E4 from "../IMG/e3.png"
import Sponsor1 from "../IMG/s3.png"
import {Swiper , SwiperSlide} from"swiper/react"
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import { useContext, useEffect, useState } from "react";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { CommercialInfo } from "../../../Modeles/Commercial";
import AfficheCommercial from "../../../Services/Magasin/AfficheCommercial";
import AfficheCommercialMagasinInAdmin from "../../../Services/Admin/AfficheCommercialMagainInAdmin";
import { map } from "jquery";
import AfficheClientMagasinInAdmin from "../../../Services/Admin/AfficheClientMagasinInAdmin";
import { ClientInfo } from "../../../Modeles/Client";
import HistoriqueMagasinService from "../../../Services/Magasin/HistoriqueMagasinService";
import { Commande } from "../../../Modeles/Commande";
import AfficheCommandeByMagasin from "../../../Services/Admin/AfficheCommandeByMagasin";
import MagasinInfoService from "../../../Services/Magasin/MagasinInfo";
import { MagasinInfo } from "../../../Modeles/MagasinModel";
import { info } from "console";
import { MagasinContext } from "../../../Context/MagasinContext";
import axios, { AxiosError } from "axios";
export interface ProductType{
  product : ArticleInfo[],
  messageErros:string,
}
export interface CommercialType{
  commercial : CommercialInfo[],
  messageErrosCommercial:string,
}
export interface ClientType{
  Client : ClientInfo[],
  messageErrosClient:string,
}
export interface CommandeType{

  Commande : Commande[],
  messageErrosCommande:string,
}
export interface MagasinType{
  Info : MagasinInfo[],
  messageErrosInfo:string,
}
export default function EditeMagasin(){
  const magasinContext = useContext(MagasinContext);
  const MagasinId = localStorage.getItem('MagasinId');
  const panier = localStorage.getItem('paniermagasin');
    const idmagasin = MagasinId || magasinContext.id?.id;
const { id } = useParams();
// -------------------------- affiche Info Magasin --------------------
const [stateInfo, setInfo] = useState<MagasinType>({
  Info: [] as MagasinInfo[],
  messageErrosInfo: "Accune info",
});
useEffect(()=>{
  setState({...state })
  MagasinInfoService(id).getMagasinInfo()
      .then((res)=>setInfo({...stateInfo  , Info:res.data})

      )
      .catch(msg=>setInfo({...stateInfo  , Info:msg.messageErrosCommande}))
},[]);
const {Info , messageErrosInfo} = stateInfo
// -------------------------- affiche commande by magasin --------------------
const [stateCommande, setstateCommande] = useState<CommandeType>({
  Commande: [] as Commande[],
  messageErrosCommande: "Accune commande",
});
useEffect(()=>{
  setState({...state })
    AfficheCommandeByMagasin(id).GetCommande()
      .then((res)=>setstateCommande({...stateCommande  , Commande:res.data})

      )
      .catch(msg=>setstateCommande({...stateCommande  , Commande:msg.messageErrosCommande}))
},[]);
const {Commande , messageErrosCommande} = stateCommande
// ------------------------affiche client by id magasin ---------------------------
const [stateClient, setstateClient] = useState<ClientType>({
  Client: [] as ClientInfo[],
  messageErrosClient: "Accune commercial",
});

useEffect(() => {
  setState({ ...state });
  AfficheClientMagasinInAdmin(id).GetClient()
  .then((res) => setstateClient({ ...stateClient, Client: res.data }))
  .catch(msg => setstateClient({ ...stateClient, Client: msg.messageErrosClient }));
}, []);

const { Client, messageErrosClient } = stateClient;


//  --------------------- affiche commercial by id magasin -----------------------
const [stateCommercial, setStateCommercial] = useState<CommercialType>({
  commercial: [] as CommercialInfo[],
  messageErrosCommercial: "Accune commercial",
});

useEffect(() => {
  setState({ ...state });
  AfficheCommercialMagasinInAdmin(id).GetCommercial()
    .then((res) => setStateCommercial({ ...stateCommercial, commercial: res.data }))
    .catch(msg => setStateCommercial({ ...stateCommercial, commercial: msg.messageErrosCommercial }));
}, []);

const { commercial, messageErrosCommercial } = stateCommercial;
  

  const [state , setState] = useState<ProductType>({
    product:[] as ArticleInfo[],
    messageErros : "accune produit",

})
const [stateMagasin , setStateMagasin] = useState<ProductType>({
  product:[] as ArticleInfo[],
  messageErros : "accune produit",

})
const bloqué=async (e:any,id:any):Promise<any>=>{
  console.log(id)
  e.preventDefault();
  const formData = new FormData();
  formData.append("id", id);
  try {
    await axios.post(`${process.env.REACT_APP_PHP_APP_URL}/bloque-magasin`, formData)
    .then(({data})=>{
      console.log(data)
    });
  } catch (error: AxiosError | any) {
    } 
}
// -------------affiche magasin info by id -----------------
useEffect(()=>{
    setState({...state })
        AfficheProductsService().getProduct()
        .then((res)=>setState({...state  , product:res.data})

        )
        .catch(msg=>setState({...state  , product:msg.messageErros}))
},[]);
const {product , messageErros} = state
const handleGoBack = () => {
  window.history.back();
};
    return<>
    <Sidebare/>
      {
      Info &&
  Info.length>0 ? Info.map(inf=>(
    <div className="container mt-5">
      
    <div className="barRetour">
      <span className="iconRetour">
        <i onClick={handleGoBack} className="bi bi-arrow-left-short"></i>
        
      </span>
      <div className="marque">
    <h4> {inf.NomMagasin}</h4>
    <h5>{inf.status === "active" ? <h5>Active <i className="actif bi bi-circle-fill"></i></h5> : <h5>bloqué <i className="text-danger actif bi bi-circle-fill"></i></h5> }</h5>
</div>
    </div>


    <div className="inputForm">
    <div className="row mb-3">
  <div className="col">
        <span>Nom de magasin</span>
    <input type="text" defaultValue={inf.NomMagasin} className="form-control" id="nom" aria-label="First name" />
  </div>
  
  <div className="col-7" id="inputTele">
    <div className="inputTele">

  <span>Nom complet de proprietaire</span>
    <input defaultValue={inf.Nom_complet_propriétaire} type="text" className="form-control" id="tele"  />
    </div>

  </div>
</div>
<div className="row mb-3">
  <div className="col">
        <span>Email</span>
    <input type="text" defaultValue={inf.email} className="form-control" id="nom" aria-label="First name" />
  </div>
  
  <div className="col-7" id="inputTele">
    <div className="inputTele">

  <span>mot de passe</span>
    <input type="text" defaultValue={inf.password}  className="form-control" id="tele"  />
    </div>

  </div>
</div>
<div className="col-12">
<span>Localisation</span>
<input type="text" defaultValue={inf.Adresse}  className="form-control" id="inputAddress"  />

</div>

     <div className="text-center mt-3">
     <Link className="bloque" to="" onClick={(e) => bloqué(e, id)}><h6 className="mb-5">{inf.status === "active" ? "bloqué ce magasin" : inf.status === "bloqué" ? "active magasin" : ""}
     <i className="bi bi-dash-circle"></i></h6></Link>
 <button className="btnChangeInfo mx-auto">Change les infos </button>
</div>
    </div>

<div className="categorieSlide">

<Swiper
      
      freeMode={true}
      grabCursor={true}
      className="container mt-5 myswiper"
      breakpoints={{
        0:{
          slidesPerView:1,
          spaceBetween:10,
        },
        480:{
          slidesPerView:2,
          spaceBetween:10,
        },
        700:{
          slidesPerView:4,
          spaceBetween:10,
        }
      }}
      >
                {product.length>0? product.map(pro=>(

        <SwiperSlide>

        <div className="box">
          <div className="slidImage">
            <img src={E1} alt="" />
            <div className="overlay">
            </div>
          </div>
          <div className="detailBoxEdite">
            <div className="type">
              <p>{pro.Designation}</p>
            </div>
            <p className="price">{pro.PrixVenteArticleTTC} MAD <span className="pu">/ P.U</span> </p>
          </div>
        </div>
        </SwiperSlide>
                )):""
              }
        </Swiper>
</div>
<div className="magasin-commande">
<h5 className="mt-3 mb-5 text-center">List des Commandes</h5>

<table className="table table-bordered mt-3 mb-5">
    <thead>
      <tr>
        <th scope="col" className="ncom">N de commande</th>
        <th scope="col">Statut</th>
        <th scope="col">Montant</th>
        <th scope="col">Date</th>

      </tr>
    </thead>
    <tbody>
    {Commande && Commande.length > 0 ? (
  Commande.slice(0, 3).map(com => (
    <tr key={com.IdCommande}>
      <td>{com.IdCommande}</td>
      <td>{com.Statut}</td>
      <td>{com.TotalCommandeHT} MAD</td>
      <td>{com.DateCommande}</td>
    </tr>
  ))
) : (
  <div className="row">
    <div className="col text-center">
      <h6><i className="bi bi-info"></i> {messageErrosCommande}</h6>
    </div>
  </div>
)}
    </tbody>

  </table>
    {Commande.length>0?<Link to={"/historiques"} className="vopc">Voire plus</Link>:""}
</div>
<div className="row mb-3">
<div className="col magasin-commercial">
  <h5>List Commercials</h5>
{
  commercial && commercial.length>0 ? commercial.map(com=>(
  <div className="profil-commercial d-flex">
  <img src={profil} alt="" />
      <div className="cordoneé-commercial">
      <Link to={`/magasin/${inf.id}/commercial/${com.id}`} className="voir-commercial" >Voir Plus <i className="bi bi-box-arrow-up-right"></i></Link>
        <h6><span>Nom : </span> {com.Nom} {com.prenom}</h6>
        <h6><span>Email : </span> {com.email}</h6>
        <h6><span>Mot de passe : </span>{com.password}</h6>
        </div>
  </div>

)):
<div className="row">
  <div className="col text-center">
    <h6><i className="bi bi-info"></i> {messageErrosCommercial}</h6>
  </div>
</div>
}
</div>

<div className="col magasin-client">
<h5>List Client</h5>
{Client&& Client && Client.length>0 ? Client.map(cl=>(

<div className="profil-client d-flex">
            <img src={profil} alt="" />
            <div className="cordoneé-client">
              
        <Link to={`/magasin/${inf.id}/client/${cl.IdClient}`} className="voir-client" >Voir Plus <i className="bi bi-box-arrow-up-right"></i></Link>
            <h6><span>Nom : </span> {cl.NomClient} {cl.PasswordClient}</h6>
        <h6><span>Email : </span> {cl.EmailClient}</h6>
        <h6><span>Mot de passe : </span>{cl.PasswordClient}</h6>
        </div>
    </div>
)):
<div className="row">
  <div className="col text-center">
    <h6><i className="bi bi-info"></i> {messageErrosClient}</h6>
  </div>
</div>

}
  
</div>
</div>
</div>
)):""
}

    </>
}