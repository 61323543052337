import axios from "axios";

export default function GetNotificationNoRead(){
    
    const MagasinId = localStorage.getItem('MagasinId'); 
    const urlGetNotificationNoRead = `${process.env.REACT_APP_PHP_APP_URL}/magasins/${MagasinId}/notification/noread`;
    const getNotif=()=>{
        return (axios.get(urlGetNotificationNoRead));
    }
    return {
        getNotif
    }
}