import { Link } from "react-router-dom";
import SideBareCommercial from "../SideBareCommercial/SideBareCommercial";

import "./AfficheProduit.css"
import IMGProduit from "../../Admin/IMG/1.png"
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { useContext, useEffect, useState } from "react";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import AfficheArticleInCommercial from "../../../Services/Magasin/AfficheArticleInCommercial";
import { MagasinContext } from "../../../Context/MagasinContext";

export default function AfficheProduitCommercial() {
  const magasinContext = useContext(MagasinContext);
  const MagasinId = localStorage.getItem('CommercialId');
  const CommercialName = localStorage.getItem('CommercialName');
  const id = MagasinId || magasinContext.id?.id;
  const nameMagasin = CommercialName || magasinContext.id?.nom;
  const [search, setSearch] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<ArticleInfo[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 12;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.trim()); // Trim whitespace from search input
  };

  useEffect(() => {
    AfficheArticleInCommercial().getArticle()
      .then((res) => {
        const filtered = res.data.filter((pro: ArticleInfo) => {
          const searchTerm = search.toLowerCase();
          const designation = pro.Designation.toLowerCase().trim();
          const refArticle = pro.RefArticle.toString().toLowerCase().trim();
          const sub = pro.LibelleSubstitut?.toString().toLowerCase().trim();
          return searchTerm === "" ||
            designation.includes(searchTerm) ||
            refArticle.startsWith(searchTerm) ||
            (sub && sub.startsWith(searchTerm));
        });
        setFilteredProducts(filtered);
      })
      .catch(err => console.error(err));
  }, [id, search]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <SideBareCommercial />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
      <div className="allproductCommercial">
        <br />
        <div className="form formcommercial">
          <i className="fa fa-search" />
          <input type="text" onChange={handleSearch} className="form-control form-input" placeholder="Rechercher un produit, ref .." />
          <span className="left-pan"><i className="bi bi-sliders"></i></span>
        </div>
        <div className="row container mx-auto">
          {currentRecords.map((pro) => (
            <div className="col-lg-4 col-md-3 col-12" key={pro.IdArticle}>
              <div className="box boxcomercial">
                <div className="slidImages bar1">
                  <img src={IMGProduit} alt="" />
                  <p>{pro.Designation}</p>
                </div>
                <div className="bar2">
                  <ul>
                    <li><b>. </b><span className="vent">+50</span> Ventes</li>
                    <li><b>. </b><span className="annule">10</span> Annulés</li>
                    <li><b>. </b><span className="remborse">0</span> Remboursés</li>
                  </ul>
                </div>
                <div className="bar3">
                  <Link className="buttonfleche" to=""><button><i className="bi bi-arrow-up-right"></i></button></Link>
                </div>
              </div>
            </div>
          ))}
        </div>

        {pageNumbers.length > 1 && (
          <nav>
            <ul className="pagination">
              <li className="page-item">

                <button className="page-link" style={{ color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>Précédent</button>

              </li>
              {pageNumbers.map((number) => (
                <li className="page-item" key={number}>
                  <button className={`page-link ${currentPage === number ? 'active-button' : ''} page-number`} onClick={() => handleChangePage(number)}>{number}</button>
                </li>
              ))}
              <li className="page-item">

                <button className="page-link" style={{ color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(filteredProducts.length / recordsPerPage)}>Suivant</button>

              </li>
            </ul>
          </nav>
        )}
      </div>
    </>
  );
}
