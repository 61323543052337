import { HashRouter, Routes, Route, BrowserRouter } from "react-router-dom"
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Views/Admin/HomeAdmin/Home';
import Categories from "./Views/Admin/categories/Categories";
import EditeMagasin from "./Views/Admin/magasin/EditeMagasin";
import CreateMagasin from "./Views/Admin/magasin/CreateMagasin";
import AfficheProduit from "./Views/Admin/HomeAdmin/AfficheProduit";
import AjouterProduit from "./Views/Admin/categories/AjouterProduit";
import Historique from "./Views/Admin/historique/Historique";
import { HomeMagasin } from "./Views/Magasin/HomeMagasin/HomeMagasin";
import Connexion from "./Views/Register/Connexion";
import AfficheProduitMagasin from "./Views/Magasin/HomeMagasin/AfficheProduitsMagasin";
import ProduitMagasin from "./Views/Magasin/HomeMagasin/ProduitMagasin";
import ChangeProduitMagasin from "./Views/Magasin/HomeMagasin/ChangeProduitMagasin";
import PanierMagasin from "./Views/Magasin/panierMagasin/PanierMagasin";
import Commercial from "./Views/Magasin/commercial/Commercial";
import AjouterCommercial from "./Views/Magasin/commercial/AjouterCommercial";
import HistoriqueMagasin from "./Views/Magasin/historique/HistoriqueMagasin";
import HistoriqueId from "./Views/Magasin/historique/HistoriqueId";
import AfficheCommercial from "./Views/Magasin/commercial/AfficheCommercial";
import NotificationMagasin from "./Views/Magasin/notification/NotificationMagasin";
import ConfirmeMessage from "./Views/Magasin/notification/ConfirmeMessage";
import Demande from "./Views/Magasin/HomeMagasin/Demande";
import Client from "./Views/Magasin/client/Client";
import AjouterClient from "./Views/Magasin/client/AjouterClient";
import AjouterProduitM from "./Views/Magasin/HomeMagasin/AjouterProduitM";
import Echanger from "./Views/Admin/historique/Echanger";
import Remboureser from "./Views/Admin/historique/Remboureser";
import PanierPrecedent from "./Views/Magasin/panierMagasin/PanierPrecedent";
import Encour from "./Views/Magasin/historique/Encour";
import HomeCommercial from "./Views/Commercial/Home/HomeCommercial";
import AfficheProduitCommercial from "./Views/Commercial/Produit/AfficheProduitCommercial";
import HomeVisiteur from "./Views/Utilisateure/visiteur/HomeVisiteur";
import AfficheProduitVisiteur from "./Views/Utilisateure/visiteur/AfficheProduitVisiteur";
import AddArticleService from "./Services/Admin/AddArticleService";
import MessageSuccess from "./Views/Admin/message/MessageSuccess";
import AfficheProduitService from "./Services/Admin/AfficheProduitService";
import ModifierProduit from "./Views/Admin/HomeAdmin/ModifierProduit";
import ModifierArticleService from "./Services/Admin/ModifierArticleService";
import AvisClient from "./Views/Utilisateure/visiteur/AvisClient";
import LoginService from "./Services/Authentication/LoginService";
import HomeClient from "./Views/Utilisateure/client/HomeClient";
import AfficheArticleById from "./Services/Magasin/AfficheArticleById";
import AddCommercialMagasin from "./Services/Magasin/AddCommercialMagasin";
import MessageAddCommercial from "./Views/Admin/message/MessageAddCommercial";
import AddClient from "./Services/Magasin/AddClient";
import MessageAddClient from "./Views/Admin/message/MessagAddClient";
import AddArticle from "./Services/Magasin/AddArticle";
import MessageAddArticleInMagasin from "./Views/Admin/message/MessageAddArticleInMagasin";
import Logout from "./Services/Magasin/Logout";
import AfficheArticleByIdVisiteure from "./Services/Visiteure/AfficheArticleByIdVisiteure";
import CommandeMagasin from "./Views/Magasin/commande/CommandeMagasin";
import CommandeMagasinServiec from "./Services/Magasin/CommandeMagasinService";
import SettingAdmin from "./Views/Admin/Setting/SettingAdmin";
import AssistanceAdmin from "./Views/Admin/Setting/AssistanceAdmin";
import MessageUpdateArticle from "./Views/Admin/message/MessageUpdateArticle";
import Sidebare from "./Views/Admin/Sidbare/Sidebare";
import SettingMagasin from "./Views/Magasin/Setting/SettingMagasin";
import { useState } from "react";
import AllProduit from "./Views/Admin/HomeAdmin/AllProduit";
import AllProduitMagasin from "./Views/Magasin/HomeMagasin/AllProduitMagasin";
import NotificationAdmin from "./Views/Admin/notification/NotificationAdmin";
import NotificationCommercial from "./Views/Commercial/notification/NotificationCommercial";
import DetailCommande from "./Views/Admin/historique/DetailCommande";
import PageNotFound from "./PageNotFound";
import AllProductClient from "./Views/Utilisateure/client/AllProductClient";
import AfficheProduitClientService from "./Services/Visiteure/Client/AfficheProduitClientService";
import ChangePasswordAdmin from "./Views/Admin/Setting/ChangePasswordAdmin";
import ChnageMailAdmin from "./Views/Admin/Setting/ChangeMailAdmin";
import DetailCommandeMagasin from "./Views/Magasin/historique/DetailCommandeMagasin";
import Magasin from "./Views/Admin/magasin/Magasin";

import CommandeCommercialService from "./Services/Commercial/CommandeCommercialServiec";
import AfficheCommandeCommercial from "./Services/Admin/AfficheCommandeCommercial";
import HistoriqueCommercial from "./Views/Admin/historique/HistoriqueCommercial";
import HistoriqueClient from "./Views/Admin/historique/HistoriqueClient";
import CommercialInformation from "./Views/Admin/historique/CommercialInformation";
import HistoriqueCommandeCommercial from "./Views/Commercial/Historique/HistoriqueCommandeCommercial";
import HistoriqueCommandeClient from "./Views/Utilisateure/client/Historique/HistoriqueCommandeClient";
import ClientInformation from "./Views/Admin/historique/ClientInfo";
import AfficheProduitByIdCommercial from "./Views/Commercial/Produit/AfficheProduitByIdCommercial";
import AfficheProduitByIdCommercialService from "./Services/Commercial/AfficheProduitByIdCommercialService";
import AssistanceCommercial from "./Views/Commercial/Setting/AssistanceCommercial";
import AssistanceClient from "./Views/Utilisateure/client/AssistanceClient";
import CommandeClientService from "./Services/Visiteure/Client/CommandeClientService";
import ChangePasswordCommercial from "./Views/Commercial/Setting/ChangePasswordCommercial";
import ChnageMailCommercial from "./Views/Commercial/Setting/ChangeMailCommercial";
import ChangePasswordClient from "./Views/Utilisateure/client/Setting/ChangePasswordClient";
import ChnageMailClient from "./Views/Utilisateure/client/Setting/ChangeMailClient";
import ChangePasswordMagasin from "./Views/Magasin/Setting/ChangePasswordMagasin";
import ChnageMailMagasin from "./Views/Magasin/Setting/ChangeMailMagasin";
import SettingCommercial from "./Views/Commercial/Setting/SettingCommercial";
import SettingClient from "./Views/Utilisateure/client/Setting/SettingClient";
import PanierCommercial from "./Views/Commercial/panierCommercial/PanierCommercial";
import PanierClient from "./Views/Utilisateure/client/panierClient/panierClient";

import New_page from "./Views/Utilisateure/visiteur/CreateMagasiEnligne";



/* commercial test */
import Commercial_test from "./Views/Admin/commercial-test";
import Allproductvisiteur from "./Views/Utilisateure/visiteur/AllProductVisiteur";
import AllProductVisiteur from "./Views/Utilisateure/visiteur/AllProductVisiteur";

import Panier_visiteur from "./Views/Utilisateure/visiteur/panier-visiteur";
import CreateMagasiEnligne from "./Views/Utilisateure/visiteur/CreateMagasiEnligne";
import AddMagasinenligne from "./Services/Magasin/AddMagasinenligne";
import DemandeAdmin from "./Views/Admin/demande/DemandeAdmin";
import ConfirmeDemande from "./Services/Admin/ConfirmeDemande";
import AddMagasin from "./Services/Admin/AddMagasin";
import ModifierClient from "./Views/Magasin/client/ModifierClient";

const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
);

const MagasinId = localStorage.getItem('MagasinId');
root.render(
      <>
            <BrowserRouter>
                  <Routes>
                        {/* <Route path="*" element={<PageNotFound />} /> */}
                        {/* -------------------------------- routes authentification -----------------------------*/}

                        {/* {(MagasinId == '' || MagasinId == undefined) ? */}
                              {/* : ""} */}
                              <Route path='' index element={<LoginService />} />
                                    /* -------------------------------- routes admin -----------------------------*/
                        <Route path='/home' index element={<Home />} />
                        <Route path='/articles' index element={<AllProduit />} />
                        <Route path='/articles/:id' element={<AfficheProduitService />} />
                        <Route path='/articles/:id/edit' element={<ModifierArticleService />} />
                        <Route path='/articles/categories' index element={<Categories />} />
                        <Route path='/articles/categories/add' index element={<AddArticleService />} />
                        <Route path='/magasin' index element={<Magasin />} />
                        <Route path='/magasin/add' index element={<AddMagasin />} />
                        <Route path='/magasin/:id/edit' index element={<EditeMagasin />} />
                        <Route path='/historiques' index element={<Historique />} />
                        <Route path='/notification' index element={<NotificationAdmin />} />
                        <Route path='/paramétres' index element={<SettingAdmin />} />
                        <Route path='/paramétres/change-mot-de-passe' index element={<ChangePasswordAdmin />} />
                        <Route path='/paramétres/email' index element={<ChnageMailAdmin />} />
                        <Route path='/assistance' index element={<AssistanceAdmin />} />
                        <Route path='/historiques/:id' index element={<DetailCommande />} />
                        <Route path='/historiques/commercials' index element={<HistoriqueCommercial />} />
                        <Route path='/historiques/client' index element={<HistoriqueClient />} />

                        <Route path='/historiques/:id/echanger' index element={<Echanger />} />
                        <Route path='/historiques/:id/rembourser' index element={<Remboureser />} />


                        {/* commercial test */}

                        // {/* <Route path='/historiques/:idcommande' index element={<DetailCommande/>}/> */}




                        <Route path='/magasin/:id/commercial/:id' index element={<CommercialInformation />} />
                        <Route path='/magasin/:id/client/:id' index element={<ClientInformation />} />
                        <Route path='/magasins/:id/clients/:id' index element={<ModifierClient />} />


                        {/* <Route path='/historiques/:idcommande' index element={<DetailCommande/>}/> */}

                        {/* -------------------------------- routes magasin -----------------------------*/}

                        <Route path='/magasins/:id' index element={<HomeMagasin />} />
                        <Route path='/magasins/:id/articles' index element={<ProduitMagasin />} />
                        <Route path='/magasins/:id/articles/:id' index element={<AfficheArticleById />} />
                        {/* <Route path='/magasins/:id/articles' index element={<AllProduitMagasin/>}/> */}
                        <Route path='/magasins/:id/article/:id/edit' index element={<ChangeProduitMagasin />} />
                        <Route path='/magasins/:id/panier' index element={<PanierMagasin />} />
                        <Route path='/magasins/:id/commercials' index element={<Commercial />} />
                        <Route path='/magasins/:id/commercials/add' index element={<AddCommercialMagasin />} />
                        <Route path='/magasins/:id/commercials/:id' index element={<AfficheCommercial />} />
                        <Route path='/magasins/:id/notifications' index element={<NotificationMagasin />} />
                        <Route path='/magasins/:id/notifications/:id/confirme' index element={<ConfirmeMessage />} />
                        <Route path='/magasins/:id/demande' index element={<Demande />} />
                        <Route path='/magasins/:id/clients' index element={<Client />} />
                        <Route path='/magasins/:id/clients/add' index element={<AddClient />} />
                        <Route path='/magasins/:id/articles/add' index element={<AddArticle />} />
                        <Route path='/magasins/:id/panier/precedent' index element={<PanierPrecedent />} />
                        <Route path='/message' index element={<MessageSuccess />} />
                        <Route path='/message/article' index element={<MessageAddArticleInMagasin />} />
                        <Route path='/message/article/edit' index element={<MessageUpdateArticle />} />
                        <Route path='/message/commercial' index element={<MessageAddCommercial />} />
                        <Route path='/message/client' index element={<MessageAddClient />} />
                        <Route path='/magasins/:id/historiques' index element={<HistoriqueMagasin />} />
                        <Route path='/magasins/:id/commande' index element={<CommandeMagasinServiec />} />

                        <Route path='/magasins/:id/historiques/:id' index element={<DetailCommandeMagasin />} />
                        <Route path='/magasins/:id/historiques/encour/id' index element={<Encour />} />

                        <Route path='/magasins/:id/paramétres' index element={<SettingMagasin />} />
                        <Route path='/magasins/:id/paramétres/change-mot-de-passe' index element={<ChangePasswordMagasin />} />
                        <Route path='/magasins/:id/paramétres/change-email' index element={<ChnageMailMagasin />} />
                        <Route path='/demandes' index element={<DemandeAdmin />} />
                        <Route path='/demandes/:id/confirmer' index element={<ConfirmeDemande />} />
                        <Route path='/logout' index element={<Logout />} />

                        // {/* ------------------------------ routes Commercial ---------------------------*/}


                        <Route path='/commercials/:id' index element={<HomeCommercial />} />
                        <Route path='/commercials/:id/articles' index element={<AfficheProduitCommercial />} />
                        <Route path='/commercials/:id/articles/:id' index element={<AfficheProduitByIdCommercialService />} />
                        <Route path='/commercials/:id/notification' index element={<NotificationCommercial />} />
                        <Route path='/commercials/:id/commande' index element={<CommandeCommercialService />} />
                        <Route path='/commercials/:id/historique' index element={<HistoriqueCommandeCommercial />} />
                        <Route path='/commercials/:id/assistance' index element={<AssistanceCommercial />} />
                        <Route path='/commercials/:id/paramétres' index element={<SettingCommercial />} />
                        <Route path='/commercials/:id/paramétres/change-mot-de-passe' index element={<ChangePasswordCommercial />} />
                        <Route path='/commercials/:id/paramétres/change-email' index element={<ChnageMailCommercial />} />
                        <Route path='/commercials/:id/panier' index element={<PanierCommercial />} />



                        {/* -------------------------------- routes Visiteur -----------------------------*/}

                        <Route path='/visiteurs' index element={<HomeVisiteur />} />
                        <Route path='/visiteurs/articles/:id' index element={<AfficheArticleByIdVisiteure />} />
                        <Route path='/avis/clients' element={<AvisClient />} />
                        <Route path='/visiteurs/articles' index element={<AllProductVisiteur />} />

                        <Route path='/visiteurs/panier' index element={<Panier_visiteur />} />

                        <Route path='/create-magasin' index element={<AddMagasinenligne />} />


                        {/* -------------------------------- routes Client -----------------------------*/}

                        <Route path='/clients/:id' index element={<HomeClient />} />

                        <Route path='/clients/:id/articles/:id' index element={<AfficheProduitClientService />} />
                        <Route path='/clients/:id/articles' index element={<AllProductClient />} />
                        <Route path='/clients/:id/articles/:id' index element={<AfficheProduitClientService />} />
                        <Route path='/clients/:id/historique' index element={<HistoriqueCommandeClient />} />
                        <Route path='/clients/:id/assistance' index element={<AssistanceClient />} />
                        <Route path='/clients/:id/commande' index element={<CommandeClientService />} />
                        <Route path='/clients/:id/paramétres' index element={<SettingClient />} />
                        <Route path='/clients/:id/paramétres/change-mot-de-passe' index element={<ChangePasswordClient />} />
                        <Route path='/clients/:id/paramétres/change-email' index element={<ChnageMailClient />} />
                        <Route path='/client/:id/panier' index element={<PanierClient />} />



                  </Routes>
            </BrowserRouter>
      </>
);