import axios from "axios";
import { useParams } from "react-router-dom";
export default function GetGommercialById(id:any , idMag:any){
    const urlShowCommercial= `${process.env.REACT_APP_PHP_APP_URL}/magasin/${idMag}/commercial/${id}`;
    const GetCommercial=()=>{
        return (axios.get(urlShowCommercial));
    }
    return {
        GetCommercial
    }
}