import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebare from '../Sidbare/Sidebare';
import logoMagasin from '../IMG/s1.png';
import './magasin.css';
import { MagasinInfo } from '../../../Modeles/MagasinModel';
import AfficheMagasinsService from '../../../Services/Magasin/AfficheMagasinsService';

export interface ProductType {
  product: MagasinInfo[];
  messageErrors: string;
}

export default function Magasin() {
  const [state, setState] = useState<ProductType>({
    product: [],
    messageErrors: 'Aucun produit',
  });

  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    AfficheMagasinsService()
      .getMagasin()
      .then((res) => setState({ ...state, product: res.data }))
      .catch((msg) => setState({ ...state, messageErrors: msg.messageErrors }));
  }, []);

  const { product, messageErrors } = state;

  // Filter the products based on the search term
  const filteredProducts = product.filter((pro) =>
    pro.NomMagasin.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Sidebare />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <div className="categ">
        <div className="container">
          <div className="row height rechAA" style={{ marginLeft: '-100px' }}>
            <div className="col-md-6">
              <div className="form formMagazin">
                <i className="fa fa-search" />
                <input
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-control form-input"
                  placeholder="Recherch un magasin, ..."
                />
                <span className="left-pan">
                  <i className="bi bi-sliders"></i>
                </span>
                <Link to="/magasin/add">
                  <button className="btnAjoutee creteMagasin">
                    Créer un magasin
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentItems.length > 0 ? (
        currentItems.map((pro: any) => (
          <div key={pro.IdMagasin} className="container magasin">
            <div className="magasinInfo">
              <h5>{pro.NomMagasin}</h5>
              <img src={logoMagasin} alt="" />
            </div>
            <div className="card">
              <div className="location">
                <p>
                  <i className="bi bi-geo"></i> {pro.VilleName}
                </p>
                <div className="etoile">
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <span>(30K)</span>
                </div>
              </div>
              <p className="descmag">Depot : ---- {pro.DepotName} -----</p>
              <Link to={`/magasin/${pro.IdMagasin}/edit`}>
              <a className="voirPlus" href=""> plus de details <i className="bi bi-arrow-right-short" />
                </a>
              </Link>
            </div>
          </div>
        ))
      ) : (
        <div className="no-produit">
                <i className="bi bi-emoji-neutral"></i><br />
                <p>Malheureusement, on n‘a pas de magasin avec ce nom .</p><br />
              </div>
      )}

      {pageNumbers.length > 1 && (
        <nav>
          <div className="pagination-container">
            <ul className="pagination" style={{ marginTop: '-20%' }}>
              <li className="page-item">
                <button
                  className="page-link"
                  style={{ color: '#FFC400', backgroundColor: 'transparent' }}
                  onClick={() => handleChangePage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Précédent
                </button>
              </li>
              {pageNumbers.map((number) => (
                <li className="page-item" key={number}>
                  <button
                    className={`page-link ${
                      currentPage === number ? 'activeButton' : ''
                    } page-number`}
                    style={{ color: '#FFC400', backgroundColor: 'transparent' }}
                    onClick={() => handleChangePage(number)}
                  >
                    {number}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button
                  className="page-link"
                  style={{ color: '#FFC400', backgroundColor: 'transparent' }}
                  onClick={() => handleChangePage(currentPage + 1)}
                  disabled={
                    currentPage === Math.ceil(filteredProducts.length / itemsPerPage)
                  }
                >
                  Suivant
                </button>
              </li>
            </ul>
          </div>
        </nav>
      )}
    </>
  );
}
