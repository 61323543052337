import axios from "axios";

export default function MagasinInfoService(id:any){
    const urlShowMagasins = `${process.env.REACT_APP_PHP_APP_URL}/magasins/${id}/info`;
    const getMagasinInfo=()=>{
        return (axios.get(urlShowMagasins));
    }
    return {
        getMagasinInfo,
    }
}