import SideBareMagasin from "../SideBareMagasin/SideBareMagasin";
import ProductImage from "../../Admin/IMG/2.png"
import ProductImage1 from "../../Admin/IMG/3.png"
import ProductImage2 from "../../Admin/IMG/e1.png"

import "./ProduitMagasin.css"
import { Link } from "react-router-dom";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { useContext, useEffect, useState } from "react";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import { MagasinContext } from "../../../Context/MagasinContext";
import AfficheArticleInMagasin from "../../../Services/Magasin/AfficheArticleInMagasin";

export interface ProductType {
    product: ArticleInfo[],
    messageErrors: string,
}

export default function ProduitMagasin() {
    const magasinContext = useContext(MagasinContext);
    const MagasinId = localStorage.getItem('MagasinId');
    const id = MagasinId || magasinContext.id?.id;

    const [search, setSearch] = useState("");
    const [state, setState] = useState<ProductType>({
        product: [] as ArticleInfo[],
        messageErrors: "aucun produit",
    });

    const [filteredProducts, setFilteredProducts] = useState<ArticleInfo[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const [prevPage, setPrevPage] = useState(1);

    const handleChangePage = (pageNumber: number) => {
        const totalPages = Math.ceil(filteredProducts.length / recordsPerPage);
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            if (search === "") {
                setPrevPage(pageNumber);
            }
        }
    };

    const handelSearch = (e: any) => {
        setSearch(e.target.value);
        if (e.target.value !== "") {
            setCurrentPage(prevPage);
        }
    };

    useEffect(() => {
        setState((prevState) => ({ ...prevState, messageErrors: "aucun produit" }));
    
        AfficheArticleInMagasin().getArticle()
            .then((res: { data: any[]; }) => {
                const filtered = res.data.filter((pro: ArticleInfo) => {
                    const searchTerm = search.trim().toLowerCase().replace(/\s+/g, '');
                    const designation = pro.Designation.toLowerCase().replace(/\s+/g, '');
                    const refArticle = pro.RefArticle.toString().toLowerCase().replace(/\s+/g, '');
                    const sub = pro.LibelleSubstitut?.toString().toLowerCase().replace(/\s+/g, '');
    
                    return searchTerm === "" ||
                        designation.includes(searchTerm) ||
                        refArticle.startsWith(searchTerm) ||
                        (sub && sub.startsWith(searchTerm));
                });
    
                setFilteredProducts(filtered);
                setState((prevState) => ({ ...prevState, product: res.data }));
            })
            .catch((msg: { messageErrors: any; }) => setState((prevState) => ({ ...prevState, messageErrors: msg.messageErrors })));
    }, [search]);
    
    

    const { product, messageErrors } = state;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);

    const renderProducts = () => {
        const normalizedSearch = search.trim().toLowerCase().replace(/\s+/g, '');
        const filteredProductList = filteredProducts.filter((pro) => {
            const designation = pro.Designation.toLowerCase().replace(/\s+/g, '');
            const refArticle = pro.RefArticle.toString().toLowerCase().replace(/\s+/g, '');
            const sub = pro.LibelleSubstitut?.toString().toLowerCase().replace(/\s+/g, '');
    
            return normalizedSearch === "" ||
                designation.includes(normalizedSearch) ||
                refArticle.startsWith(normalizedSearch) ||
                (sub && sub.startsWith(normalizedSearch));
        });
    
        return filteredProductList.map((pro) => (
            <div className="itemPro" key={pro.IdArticle}>
                <div className="card" id="allItems">
                    <div className="iconPoint">
                        <i className="bi bi-three-dots-vertical"></i>
                    </div>
                    <Link className="txN" to={`/magasins/${id}/articles/${pro.IdArticle}`}>
                        <div className="contentMagasinP">
                        {
                        pro.réduction && pro.réduction > 0 ? (
                          <span className="reduction-product">{pro.réduction}% de réduction</span>
                        ) : (
                          ""
                        )
                      }
                            <div className="Itemimg">
                                <img src={ProductImage} alt="" /><br />
                                <p>{pro.PrixVenteArticleTTC} MAD<span className="pu"> / p.u</span></p>
                            </div>
                            <div className="infoPM">
                                <p>{pro.Designation}</p><br />
                                <span>+1000 ventes </span>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        ));
    };
    
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredProducts.length / recordsPerPage); i++) {
        pageNumbers.push(i);
    }
    
    return (
        <>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
    
            <SideBareMagasin />
            <div className="container mt-5">
                <div className="row height">
                    <div className="col-md-6">
                        <div className="form">
                            <i className="fa fa-search" />
                            <input type="text" onChange={handelSearch} className="form-control form-reherch form-input" placeholder="Rechercher un produit, ref .." />
                            <span className="left-pan"><i className="bi bi-sliders"></i></span>
                            <Link to={`/magasins/${id}/articles/add`}><button className="btnAjouteM">Ajouter un produit</button></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {filteredProducts.length > 0 ? (
                    renderProducts()
                ) : (
                    <div className="no-produit">
                        <i className="bi bi-emoji-neutral"></i><br />
                        <p>Malheureusement, on n'a pas ce produit pour l'instant.</p><br />
                        <Link to={"https://api.whatsapp.com/send?phone=212661718081"} target="_blank" className="botton-remplir"><button><i className="bi bi-whatsapp"></i>Contactez-nous</button></Link>
                    </div>
                )}
                {pageNumbers.length > 1 && (
                    <nav>
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="page-link" style={{ color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
                                    Précédent
                                </button>
                            </li>
                            {pageNumbers.map((number) => (
                                <li className="page-item" key={number}>
                                    <button className={`page-link ${currentPage === number ? 'active-Button' : ''} page-number`} style={{ color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(number)}>{number}</button>
                                </li>
                            ))}
                            <li className="page-item">
                                <button className="page-link" style={{ color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(currentPage + 1)}>Suivant</button>
                            </li>
                        </ul>
                    </nav>
                )}
            </div>
        </>
    );
}    