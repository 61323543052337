import React, { useContext, useEffect, useState } from "react";
import SideBareMagasin from "../SideBareMagasin/SideBareMagasin";
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import 'swiper/css/autoplay';
import { Autoplay, FreeMode } from 'swiper/modules';
// import "/swiper/swiper-bundle.min.css";

import "./HomeMagasin.css";
import { Link } from "react-router-dom";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import AfficheArticleInMagasin from "../../../Services/Magasin/AfficheArticleInMagasin";
import { MagasinContext } from "../../../Context/MagasinContext";
import pMagasin from "../../Admin/IMG/3.png";

export interface ProductType {
  product: ArticleInfo[];
  messageErros: string;
}

export function HomeMagasin() {
  const [search, setSearch] = useState("");
  const magasinContext = useContext(MagasinContext);
  const MagasinId = localStorage.getItem("MagasinId");
  const id = MagasinId || magasinContext.id?.id;

  const [state, setState] = useState<ProductType>({
    product: [] as ArticleInfo[],
    messageErros: "accune produit",
  });

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    AfficheArticleInMagasin()
      .getArticle()
      .then((res) =>
        setState({ product: res.data, messageErros: state.messageErros })
      )
      .catch((msg) =>
        setState({ product: [], messageErros: msg.messageErros })
      );
  }, []);

  const { product, messageErros } = state;

  return (
    <>
      <SideBareMagasin />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <div className="categ">
        <Link to={`/magasins/${id}/panier`}>
          <div className="panier">
            <i className="bi bi-cart3"></i>
            <span className="num">1</span>
          </div>
        </Link>
        <div className="container">
          <h5 className="mt-5">
            <b>Bienvenue ! </b> vous pouvez gérer votre stock en toute simplicité
          </h5>
        </div>
        <div className="container mt-5">
          <div className="row height">
            <div className="col-md-6">
              <div className="form">
                <i className="fa fa-search" />
                <input
                  onChange={handleSearch}
                  type="text"
                  className="form-control form-input"
                  placeholder="Recherch un produit , ref .."
                />
                <span className="left-pan">
                  <i className="bi bi-sliders"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid" id="ProductSlide">
          <h5 className="container">Equipements</h5>
          <Link to={`/magasins/${id}/articles`}>
            <a className="right-align" href="#">
              Voir Plus <i className="bi bi-arrow-right-short" />
            </a>
          </Link>
          <Swiper
      freeMode={true}
      grabCursor={true}
      className="container myswiper"
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 80,
        },
        600: {
          slidesPerView: 3,
          spaceBetween: 80,
        },
        800: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
      }}
      autoplay={{
        delay: 2000, 
        disableOnInteraction: false,
      }}
      modules={[Autoplay, FreeMode]}
    >
            {product && product.length > 0 ? (
              product
                .filter((pro) => {
                  const searchTerm = search.trim().toLowerCase();
                  const designation = pro.Designation.toLowerCase();
                  const refArticle = pro.RefArticle.toString().toLowerCase();
                  const sub = pro.LibelleSubstitut?.toString().toLowerCase();

                  const searchTerms = searchTerm.split(" ");

                  return searchTerms.every(
                    (term) =>
                      designation.includes(term) ||
                      refArticle.startsWith(term) ||
                      (sub && sub.startsWith(term))
                  );
                })
                .map((pro) => (
                  <SwiperSlide className="upBox" key={pro.IdArticle}>
                    <Link
                      className="hh"
                      to={`/magasins/${id}/articles/${pro.IdArticle}`}
                    >
                      <div className="box">
                      {
                        pro.réduction && pro.réduction > 0 ? (
                          <span className="reduction">{pro.réduction}% de réduction</span>
                        ) : (
                          ""
                        )
                      }
                        <div className="slidImage">
                          <img src={pMagasin} alt="" />
                          <div className="overlay"></div>
                        </div>
                        <div className="detailBoxMagasin">
                          <div className="type">
                            <p className="paran">{pro.Designation}</p>
                            <i className="bi bi-cart-plus"></i>
                          </div>
                          <div className="price">
                            <p>
                              {pro.PrixVenteArticleTTC} MAD<span>/P.U</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))
            ) : (
              <div className="no-produit">
                <i className="bi bi-emoji-neutral"></i>
                <br />
                <p>
                  Malheureusement, on n‘a pas ce produit pour l’instant.
                </p>
                <br />
                <Link
                  to={"https://api.whatsapp.com/send?phone=212661718081"}
                  target="_blank"
                  className="botton-remplir"
                >
                  <button>
                    <i className="bi bi-whatsapp"></i>Contactez-nous
                  </button>
                </Link>
              </div>
            )}
          </Swiper>
        </div>

        <div className="row container mx-auto">
          {product && product.length > 0 ? (
            product
              .filter((pro) => {
                const searchTerm = search.toLowerCase();
                const designation = pro.Designation.toLowerCase();
                const refArticle = pro.RefArticle.toString().toLowerCase();
                const sub = pro.LibelleSubstitut?.toString().toLowerCase();
                return (
                  searchTerm === "" ||
                  designation.includes(searchTerm) ||
                  refArticle.startsWith(searchTerm) ||
                  sub?.startsWith(searchTerm)
                );
              })
              .map((pro) => (
                <div className="col-lg-4 col-md-3 col-12" key={pro.IdArticle}>
                  <Link
                    className="upBox"
                    to={`/magasins/${id}/articles/${pro.IdArticle}`}
                  >
                    <div className="box">
                      <div className="slidImage">
                      {
                        pro.réduction && pro.réduction > 0 ? (
                          <span className="reduction">{pro.réduction}% de réduction</span>
                        ) : (
                          ""
                        )
                      }
                        <img src={pMagasin} alt="" />
                        <div className="overlay"></div>
                      </div>
                      <div className="detailBoxMagasin">
                        <div className="type">
                          <p className="paran">{pro.Designation}</p>
                          <i className="bi bi-cart-plus"></i>
                        </div>
                        <div className="price">
                          <p>
                            {pro.PrixVenteArticleTTC} MAD<span>/P.U</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
          ) : (
            <div className="no-produit">
              <i className="bi bi-info-lg"></i> Aucun produit
            </div>
          )}
        </div>
      </div>
    </>
  );
}
