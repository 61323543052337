import { Link } from "react-router-dom";
import SideBareMagasin from "../SideBareMagasin/SideBareMagasin";
import "./client.css";
import "./responsiveClient.css";
import { MagasinContext } from "../../../Context/MagasinContext";
import { useContext, useEffect, useState } from "react";
import { ClientInfo } from "../../../Modeles/Client";
import AfficheClient from "../../../Services/Magasin/AfficheClient";

export interface ProductType {
  product: ClientInfo[],
  messageErros: string,
}

export default function Client() {
  const magasinContext = useContext(MagasinContext);
  const [search, setSearch] = useState("");
  const MagasinId = localStorage.getItem('MagasinId');
  const id = MagasinId || magasinContext.id?.id;
  const [state, setState] = useState<ProductType>({
    product: [] as ClientInfo[],
    messageErros: "accune produit",
  });

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  useEffect(() => {
    setState({ ...state });
    AfficheClient(id).GetClient()
      .then((res) => setState({ ...state, product: res.data }))
      .catch(msg => setState({ ...state, product: msg.messageErros }));
  }, [id]);

  const { product, messageErros } = state;

  // Filter products based on search term
  const filteredProducts = product.filter((pro) => {
    const searchTerm = search.trim().toLowerCase().replace(/\s+/g, '');
    const nom = pro.NomClient.toLowerCase().replace(/\s+/g, '');
    const prenom = pro.PrenomClient.toLowerCase().replace(/\s+/g, '');
    return nom.includes(searchTerm) || prenom.includes(searchTerm);
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <SideBareMagasin />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
      <div className="container containerClient mt-5">
        <div className="row height">
          <div className="col-md-6">
            <div className="form formClient">
              <i className="fa fa-search" />
              <input
                type="text"
                onChange={handleSearch}
                className="form-control form-input"
                placeholder="Recherch un client.."
              />
              <span className="left-pan"><i className="bi bi-sliders"></i></span>
              <Link to={`/magasins/${id}/clients/add`}>
                <button id="ac" className="btnAjouteC">Ajouter un client</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="tableInfoclient">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="ncom">Prénom</th>
                <th scope="col">Nom</th>
                <th scope="col">Email</th>
                <th scope="col">Mot de pass</th>
                <th scope="col">Ville</th>
                <th scope="col">Solde</th>
                <th scope="col">action</th>

              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.length > 0 ? currentItems.map((pro, index) => (
                <tr key={index}>
                  <td>{pro.PrenomClient}</td>
                  <td>{pro.NomClient}</td>
                  <td>{pro.EmailClient}</td>
                  <td>{pro.PasswordClient}</td>
                  <td>{pro.Ville}</td>
                  <td>{pro.Credit} MAD</td>
                  <td><Link className="btn btn-success" to={`/magasins/${id}/clients/${pro.IdClient}`}>Gérer</Link></td>

                  
                </tr>
              )) : (
                <tr>
                  <td colSpan={6}>
                    <h5><i className="bi bi-info"></i> Accune Client</h5>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {pageNumbers.length > 1 && (
            <nav>
              <ul className="pagination">
                <li className="page-item">
                  <button
                    className="page-link"
                    style={{ color: "#FFC400", backgroundColor: "transparent" }}
                    onClick={() => handleChangePage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Précédent
                  </button>
                </li>
                {pageNumbers.map((number) => (
                  <li className="page-item" key={number}>
                    <button
                      className={`page-link ${currentPage === number ? 'active-Button' : ''} page-number`}
                      style={{ color: "#FFC400", backgroundColor: "transparent" }}
                      onClick={() => handleChangePage(number)}
                    >
                      {number}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    style={{ color: "#FFC400", backgroundColor: "transparent" }}
                    onClick={() => handleChangePage(currentPage + 1)}
                    disabled={currentPage === Math.ceil(filteredProducts.length / itemsPerPage)}
                  >
                    Suivant
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </>
  );
}
