
import { Link } from "react-router-dom";
import "../../Commercial/Produit/AfficheProduit.css"
import IMGProduit from "../../Admin/IMG/1.png"
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { useEffect, useState } from "react";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import Sidebare from "../Sidbare/Sidebare";
import React from 'react';
export interface ProductType {
  product: ArticleInfo[];
  messageErrors: string;
}

export default function AllProduit() {
  const [search, setSearch] = useState("");
  const [state, setState] = useState<ProductType>({
    product: [] as ArticleInfo[],
    messageErrors: "aucun produit",
  });

  const [filteredProducts, setFilteredProducts] = useState<ArticleInfo[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 16;
  const [prevPage, setPrevPage] = useState(1);

  const handleChangePage = (pageNumber: number) => {
    const totalPages = Math.ceil(filteredProducts.length / recordsPerPage);
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      if (search === "") {
        setPrevPage(pageNumber);
      }
    }
  };


  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value !== "") {
      setCurrentPage(prevPage);
    }
  };
  useEffect(() => {
    setState((prevState) => ({ ...prevState, messageErrors: "aucun produit" }));
    AfficheProductsService().getProduct()
      .then((res: any) => {
        const filtered = res.data.filter((pro: ArticleInfo) => {
          const searchTerm = search.toLowerCase();
          const designation = pro.Designation.toLowerCase();
          const refArticle = pro.RefArticle.toString().toLowerCase();
          const sub = pro.LibelleSubstitut?.toString().toLowerCase();
          return searchTerm === "" ||
            designation.includes(searchTerm) ||
            refArticle.startsWith(searchTerm) ||
            sub?.startsWith(searchTerm);
        })
        setFilteredProducts(filtered);
        setState((prevState: any) => ({ ...prevState, product: res.data }));
      })
      .catch((msg: any) => setState((prevState: any) => ({ ...prevState, messageErrors: msg.messageErrors })));
  }, [search]);

  const { product, messageErrors } = state;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);

  const renderProducts = () => {
    return <>{
      currentRecords.map((pro: any) => (
        <div className="col-lg-3 col-md-6 col-12" key={pro.IdArticle}>
          <Link className="gotoshow" to={`/articles/${pro.IdArticle}`}>
            <div className="box boxcomercial">
              <div className="slidImages bar1">
                <img src={IMGProduit} alt="" />
                <p>{pro.Designation}</p>
              </div>
              <div className="bar2">
                <p>LibelleSubstitut : <span>{pro.LibelleSubstitut ? pro.LibelleSubstitut : "N/A"}</span></p>
                <p>Reference : <span>{pro.RefArticle ? pro.RefArticle : "N/A"}</span></p>
              </div>
              <div className="bar3-admin">
                <div className="price"><p>{pro.PrixVenteArticleTTC} MAD <span>/ P.U</span></p></div>
              </div>
            </div>
          </Link>
        </div>
      ))
    }
    </>
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredProducts.length / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <Sidebare />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
      <div className="allproductCommercial categ ">

        <div className="container_div mt-5 rechAA" style={{ width: '100vw' }} >
          <div className="" style={{ display: 'flex', marginLeft: '-3%', alignItems: "center" }}>
            <div className="" style={{ width: '65vw' }}>
              <div className="position-relative">
                <i className="fa fa-search fa-2x position-absolute" style={{ color: "#C5C5C5", top: '50%', fontSize: "1.4rem", transform: 'translateY(-50%)', left: '10px' }} />
                <input type="text" onChange={handleSearch} className="form-control form-input pl-4" placeholder="Recherch un produit, ref .." />
                <i className="bi bi-sliders fa-2x position-absolute" style={{ fontSize: "1.4rem", color: "#C5C5C5", top: '50%', transform: 'translateY(-50%)', right: '10px' }} />
              </div>
            </div>
            <div className="ml-4" style={{ marginLeft: '30px' }}>
              <Link to="/articles/categories/add"><button className="btnAjoutee">Ajouter Produit</button></Link>
            </div>
          </div>
        </div>
        <div className="row">
          {product && product.length > 0 ? (
            currentRecords.length > 0 ? (
              renderProducts()
            ) : (
              <div className="no-produit">
                <i className="bi bi-emoji-neutral"></i><br />
                <p>Malheureusement, on n‘a pas ce produit pour l’instant.</p><br />
                <Link to={"https://api.whatsapp.com/send?phone=212661718081"} target="_blank" className="botton-remplir"><button><i className="bi bi-whatsapp"></i>Contactez-nous</button></Link>
              </div>
            )
          ) : (
            <div className="no-produit"><i className="bi bi-info-lg"></i>Accune product</div>
          )}
        </div>


        {pageNumbers.length > 1 && (

          <nav>
            <ul className="pagination">
              <li className="page-item">
                <button className="page-link" style={{ color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
                  Précédent
                </button>
              </li>
              {pageNumbers.map((number) => (
                <li className="page-item" key={number}>
                  <button className={`page-link ${currentPage === number ? 'active-Button' : ''} page-number`} style={{ color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(number)}>{number}</button>
                </li>

              ))}
              <li className="page-item">
                <button className="page-link" style={{ color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(currentPage + 1)}>Suivant</button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </>
  )
}
