import axios from "axios";
import { useState } from "react";
import { ArticleInfo } from "../../Modeles/ArticleModel";
import { useParams } from "react-router-dom";

export default function DetailCommandeService(id:any){
    // const {id}= useParams();    
    const urlShowProduct = `${process.env.REACT_APP_PHP_APP_URL}/admin/historique/${id}`;
    
    const getCommande=()=>{
        return (axios.get(urlShowProduct));
    }
    return {
        getCommande
    }
}