import React, { useEffect, useState } from 'react';
import Sidebare from "../Sidbare/Sidebare";
import "swiper/css";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
import 'swiper/css/autoplay';
import { Autoplay, FreeMode } from 'swiper/modules';

// Import Swiper styles
import 'swiper/swiper-bundle.css';

import "bootstrap/dist/css/bootstrap.min.css";
import ImageProduit2 from "../IMG/2.png";
import logoMagasin from "../IMG/etablisement.png";
import { Link } from "react-router-dom";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import AfficheMagasinsService from "../../../Services/Magasin/AfficheMagasinsService";
import { MagasinInfo } from "../../../Modeles/MagasinModel";
import { NotificationMagasinType } from '../../../Modeles/NotificationModel';

import GetNotificationAdmin from '../../../Services/Admin/GetNotificationAdmin';

export interface ProductType {
  product: ArticleInfo[],
  messageErros: string,
}
export interface ProductTypeMagasins {
  magasins: MagasinInfo[],
  messageErrosMagasin: string,
}

export default function Home(){
// -------------get magasins -----------------


  const [states , setStates] = useState<ProductTypeMagasins>({
    magasins:[] as MagasinInfo[],
    messageErrosMagasin : "Il n'y a pas de magasin",
})
useEffect(()=>{
    setStates({...states })
        AfficheMagasinsService().getMagasin()
        .then((res)=>setStates({...states  , magasins:res.data})

        )
        .catch(msg=>setStates({...states  , magasins:msg.messageErrosMagasin}))
},[]);  
const {magasins , messageErrosMagasin} = states
// -------------get products ------------------

  const [state , setState] = useState<ProductType>({
    product:[] as ArticleInfo[],
    messageErros : "Il n'y a pas de produit",

})
const [stateMagasin , setStateMagasin] = useState<ProductType>({
  product:[] as ArticleInfo[],
  messageErros : "accune produit",

})
useEffect(()=>{
    setState({...state })
        AfficheProductsService().getProduct()
        .then((res)=>setState({...state  , product:res.data})

        )
        .catch(msg=>setState({...state  , product:msg.messageErros}))
},[]);
const {product , messageErros} = state


    return <>
     <Sidebare/>
    	<div className="home mt-5" >
  <main className="container">
    <div className="head-title">
      
      <div className="left">
        <h5 className='mx-5'><b>Bienvenue ! </b> vous pouvez gérer votre stock en toute simplicité</h5>
      </div>
    </div>
  </main >
  </div>

  <br /> <br />

  {/* ----------------------------------- PRODUIT SLIDE ------------------------------------ */}

  <div className="container-fluid  ProductSlide mt-3">
  <div className="d-flex w-75 mx-auto justify-content-between pavp">
        <div>
          <p className="container pMagazin">Vous avez +5,000 produits</p>
        </div>
        <div>
          <Link to={"/articles"} className="">Voir Plus <i className="bi bi-arrow-right-short" /></Link>
        </div>
      </div>
    {/* <Link to={"/articles"}><a className="right-align" href="/articles">Voir Plus <i className="bi bi-arrow-right-short" /></a></Link> */}

    <Swiper
      freeMode={true}
      grabCursor={true}
      className="container myswiper"
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 80,
        },
        600: {
          slidesPerView: 3,
          spaceBetween: 80,
        },
        800: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
      }}
      autoplay={{
        delay: 2000, 
        disableOnInteraction: false,
      }}
      modules={[Autoplay, FreeMode]}
    >
        {product && product.length>0? product.map(pro=>(
          <SwiperSlide>
      <Link className="product-link" to={`/articles/${pro.IdArticle}`}>
        <div className="box">
          <div className="slide-image">
          <img src={ImageProduit2} alt="" />
            <div className="overlay">
            </div>
          </div>
          <div className="Box-info-product">
            <div className="parant-name">
            <p className="product-name">{pro.Designation}</p>
            </div>
            <div className="price"><p>{pro.PrixVenteArticleTTC} MAD <span>/ P.U</span></p></div>
          </div>
        </div>
        </Link>
        </SwiperSlide>
      )):""
      }
        </Swiper>

      <br /> <br />
      {/*----------------------------------- SPONSOR SLIDE --------------------------------------- */}
      <div className="d-flex w-75 mx-auto justify-content-between pavp">
        <div>
          <p className="container pMagazin">Vous avez +20 magasins</p>
        </div>
        <div>
          <Link to={"/magasin"} className="">Voir Plus <i className="bi bi-arrow-right-short" /></Link>
        </div>
      </div>
      <div className="mt-3 position-relative top-20 container-fluid ProductSlide w-75">
        <Swiper
          freeMode={true}
          grabCursor={true}
          className="container mt-5 myswiper"
          breakpoints={{
            0: { slidesPerView: 1, spaceBetween: 10 },
            480: { slidesPerView: 2, spaceBetween: 10 },
            700: { slidesPerView: 4, spaceBetween: 10 }
          }}
          autoplay={{
            delay: 2300, 
            disableOnInteraction: false,
          }}
          modules={[Autoplay, FreeMode]}
        >
          {magasins && magasins.length > 0 ?
            magasins.map(mag => (
              <SwiperSlide key={mag.IdMagasin}>
                <Link className="boxSponsoremagasinpart" to={`/magasin/${mag.IdMagasin}/edit`}>
                  <div>
                    <div className="slideImgSponsore">
                    
                      <h4 className="magasin-name-home-admin"><i className="bi bi-buildings" style={{fontSize:"3rem"}}></i>{mag.NomMagasin}</h4>
                      <div className="overlay"></div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            )) : <h5 className="text-center">{messageErrosMagasin}</h5>}
        </Swiper>
      </div>
    </div>

  </>
  
}
