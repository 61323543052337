import { useEffect, useState } from "react";
import Sidebare from "../Sidbare/Sidebare";
import DemandesAdmin from "../../../Services/Admin/DemandesAdmin";
import { Link, useNavigate } from 'react-router-dom';
import { DemandeType } from "../../../Modeles/DemandeModel";
import axios, { AxiosError } from "axios";
export interface demande {
    demande: DemandeType[],
    messageErros: string,
  }
  
export default function DemandeAdmin(){
  const navigate = useNavigate();
  const [message , setMessage]=useState("");
  const [messageError , setMessageError]=useState("");
    const [state, setState] = useState<demande>({
        demande: [] as DemandeType[],
        messageErros: "Aucune demande",
      });
      const active=async (e:any,id:any):Promise<any>=>{
        console.log(id)
        e.preventDefault();
        const formData = new FormData();
        // formData.append("id", id);
        try {
          await axios.post(`${process.env.REACT_APP_PHP_APP_URL}/demande/${id}/active`, formData)
          .then(({data})=>{
            setMessage('Le magasin a été activée avec succès')
            const timeoutId = setTimeout(() => {
              navigate(`/home`);
            }, 3000);
          });
        } catch (error: AxiosError | any) {
            setMessageError('error')
            const timeoutId = setTimeout(() => {
            setMessage('')
            }, 3000);
    
          }
      }
      const supprimer=async (e:any,id:any):Promise<any>=>{
        e.preventDefault();
        const formData = new FormData();
        // formData.append("id", id);
        try {
          await axios.get(`${process.env.REACT_APP_PHP_APP_URL}/demande/${id}/supprimer`)
          .then(({data})=>{
            setMessageError('Le magasin a été supprimer avec succès');
            const timeoutId = setTimeout(() => {
              setMessageError('')
              window.location.reload();
            }, 3000);
          });
        } catch (error: AxiosError | any) {
            const timeoutId = setTimeout(() => {
            setMessage('')
            }, 3000);
    
          }
      }
      const itemsPerPage = 4;
      const [currentPage, setCurrentPage] = useState(1);
      const [searchTerm, setSearchTerm] = useState("");
    
      const handleChangePage = (pageNumber: number) => {
        setCurrentPage(pageNumber);
      };
    
      const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
      };
    
      useEffect(() => {
        DemandesAdmin().GetCommande()
          .then((res) => setState({ ...state, demande: res.data }))
          .catch((msg) => setState({ ...state, demande: msg.messageErros }));
      }, []);
    
      const { demande, messageErros } = state;
      const filteredProducts = demande.filter(p => p.NomMagasin.toLowerCase().includes(searchTerm.toLowerCase()));
    
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = filteredProducts.length > 0 ? filteredProducts.slice(indexOfFirstItem, indexOfLastItem) : [];
    
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
        pageNumbers.push(i);
      }
    return<>
    <Sidebare/>
    <div className="container">
        <h3 className="mt-5 mb-5">Bonjour ! Consultez vos dernier demandes </h3>
    <div className="tableInfo">
    {
        messageError !="" ? 
        <div className="alert alert-danger" role="alert">
  {messageError}<i className="bi bi-patch-check-fill"></i>
</div>
        :""
      }
      {
        message !="" ? 
        <div className="alert alert-success" role="alert">
  {message}<i className="bi bi-patch-check-fill"></i>
</div>
        :""
      }
        
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col" className="ncom">Id</th>
                <th scope="col">Nom</th>
                <th scope="col">Nom magasin</th>
                <th scope="col">Adresse</th>
                <th scope="col">email</th>
                <th scope="col">mote de pass</th>
                <th scope="col">Statut</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {
              currentItems.length > 0 ? currentItems.map((pro, index) => (
                <tr key={index}>
                  <td>{pro.id}</td>
                  <td>{pro.nom}</td>
                  <td>{pro.NomMagasin}</td>
                  <td>{pro.adress}</td>
                  <td>{pro.email}</td>
                  <td>{pro.password}</td>
                  <td style={{ color: pro.status === 'active' ? 'green' : pro.status === 'non active' ? "red":"" }}>{pro.status}</td>
                  <td>{pro.date_creation}</td>
                  <td>
                    <Link onClick={(e) => active(e,pro.id)} to=""><a href="" className="btn btn-success mb-1">Activer</a></Link>
                    <br />
                    <Link onClick={(e) => supprimer(e,pro.id)} to=""><a href="" className="btn btn-danger">Supprimer</a></Link>
                  </td>
                </tr>
              )) : <h5 className="mt-5 text-center">Pas de Demande</h5>
              }
            </tbody>
          </table>
          {pageNumbers.length > 1 && (
            <nav>
              <ul className="pagination" style={{ marginBottom: "15%" }}>
                <li className="page-item">
                  <button className="page-link" style={{ color: "#FFC400", fontSize: "0.7rem", backgroundColor: "transparent",marginBottom:"2%" }} onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
                    Précédent
                  </button>
                </li>
                {pageNumbers.map((number) => (
                  <li className="page-item" key={number}>
                    <button className={`page-link ${currentPage === number ? 'activeButton' : ''} page-number`} style={{ fontSize: "0.7rem", color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(number)}>{number}</button>
                  </li>
                ))}
                <li className="page-item">
                  <button className="page-link" style={{ fontSize: "0.7rem", color: "#FFC400", backgroundColor: "transparent" }} onClick={() => handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(filteredProducts.length / itemsPerPage)}>
                    Suivant
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </div>
    </div>
    </>
}