import { Link } from "react-router-dom";
import Sidebare from "../Sidbare/Sidebare";
import "./historique.css"
import { useEffect, useState } from "react";
import HistoriqueCommande from "../../../Services/Admin/HistoriqueCommande";
import { Commande, CommandeClient, CommandeCommercial } from "../../../Modeles/Commande";
import AfficheCommandeCommercial from "../../../Services/Admin/AfficheCommandeCommercial";
import AfficheCommandeClient from "../../../Services/Admin/AfficheCommandeClient";
export interface commandeType{

  product : CommandeClient[],
  messageErros:string,
}
export default function HistoriqueClient(){
  const [state , setState] = useState<commandeType>({
    product: [] as CommandeClient[],
    messageErros : "Aucune commande",
  });

const itemsPerPage = 4;
const [currentPage, setCurrentPage] = useState(1);
const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  setSearchTerm(e.target.value);
};
const [searchTerm, setSearchTerm] = useState("");
const handleChangePage = (pageNumber: number) => {
  setCurrentPage(pageNumber);
};

useEffect(()=>{
    setState({...state })
    AfficheCommandeClient().GetCommandeClient()
        .then((res)=>setState({...state  , product:res.data})

        )
        .catch(msg=>setState({...state  , product:msg.messageErros}))
},[]);
const {product , messageErros} = state
const filteredProducts = product.filter(p => p.NomClient.toLowerCase().includes(searchTerm.toLowerCase()));

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;

const currentItems = filteredProducts.length > 0 ? filteredProducts.slice(indexOfFirstItem, indexOfLastItem) : [];



const pageNumbers = [];
if (Array.isArray(product)) {
  for (let i = 1; i <= Math.ceil(product.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
}

    return<>
    <Sidebare/>
    <div className="container mt-5">

    <h4>Historique des commandes</h4>
    <div className="filtreElement">
    <Link to="" className="btnFilterS"><a>Cette semaine</a></Link>
    <Link to="" className="btnFilterM"><a >Ce mois </a></Link> 
    </div>
    <div className="filter-by-role">
      <Link className="filter-role" to={"/historiques"}>Magasin <i className="bi bi-arrow-up-right"></i></Link>
      <Link className="filter-role" to={"/historiques/commercials"}>Commercial <i className="bi bi-arrow-up-right"></i></Link>
      <Link className="active-filter mx-4" to={"/historiques/client"}>Client <i className="bi bi-arrow-up-right"></i></Link>
    </div>
    <div className="container_div mt-5" style={{ width: '25vw' , marginLeft: '2%'}} >
          <div className="" style={{ display: 'flex', marginLeft: '-7%',marginTop:"-28%" , alignItems: "center" }}>
            <div className="container_div2" style={{ width: '65vw' }}>
              <div className="position-relative">
                <i className="fa fa-search fa-2x position-absolute" style={{ color: "#C5C5C5", top: '50%', fontSize: "1.4rem", transform: 'translateY(-50%)', left: '10px' }} />
                <input
                  type="text"
                  className="form-control form-input pl-4"
                  placeholder="Recherch commande .."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />                <i className="bi bi-sliders fa-2x position-absolute" style={{ fontSize: "1.4rem", color: "#C5C5C5", top: '50%', transform: 'translateY(-50%)', right: '10px' }} />
              </div>
            </div>
          </div>
        </div>
    <div className="tableInfo">
  <table className="table table-bordered">
    <thead>
      <tr>
        <th scope="col" className="ncom">N de commande</th>
        <th scope="col">NomeCommercial</th>
        <th scope="col">Montant</th>
        <th scope="col">Statut</th>
        <th scope="col">details</th>
        <th scope="col">Date</th>
        <th scope="col">Action</th>
        <th scope="col">Demandez de</th>

      </tr>
    </thead>
    <tbody>
    {currentItems && currentItems.length > 0 ? currentItems.map((pro, index) =>(
      <tr key={index}>
        <td>{pro.id}</td>
        <td>{pro.NomClient}</td>
        <td>{pro.Total} MAD</td>
        <td style={{ color: pro.Statut === 'confirme' ? 'green' : pro.Statut === 'annuler' ? 'red' : 'black' }}>{pro.Statut}</td>
        <td><Link className="vdbtn" to={``}>Voir detail <i className ="bi bi-eye-fill"></i></Link></td>
        <td>{pro.Date}</td>
        <td><Link to={``}><a href="" className="btn btn-success mb-1">Confirmer</a></Link>
        <br />
        <Link to={``}><a href="" className="btn btn-danger">Annuler</a></Link>
        </td>
        <td><Link to={`/historiques/${pro.id}/echanger`}><a href="" className="remborser">Se remborser</a></Link>
        <br />
        <Link to={`/historiques/${pro.id}/rembourser`}><a href="" className="echanger">Echanger</a></Link>
        </td>
      </tr>
      )):<h5 className="mt-5 text-center">Pas de commande </h5>
    }
    </tbody>
  </table>
  {pageNumbers.length > 1 && (

<nav>
<ul className="pagination" style={{marginBottom:"5%"}}>
    <li className="page-item">
      <button className="page-link" style={{ color: "#FFC400",fontSize:"0.7rem" , backgroundColor: "transparent"}} onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>
        Précédent
      </button>
    </li>
    {pageNumbers.map((number) => (
      <li className="page-item" key={number}>
        <button className={`page-link ${currentPage === number ? 'activeButton' : ''} page-number`} style={{fontSize:"0.7rem" , color: "#FFC400", backgroundColor: "transparent"}}  onClick={() => handleChangePage(number)}>{number}</button>
      </li>
    
    ))}
    <li className="page-item">
      <button className="page-link" style={{fontSize:"0.7rem" , color: "#FFC400", backgroundColor: "transparent"}} onClick={() => handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(product.length / itemsPerPage)}>
        Suivant
      </button>
    </li>
  </ul>
</nav>
)}
</div>


</div>

        
    </>
}