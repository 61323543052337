import axios from "axios";
import { useParams } from "react-router-dom";

export default function AfficheClientMagasinInAdmin(id:any){
    const urlShowClientl= `${process.env.REACT_APP_PHP_APP_URL}/admin/magasin/${id}/clients`;
    const GetClient=()=>{
        return (axios.get(urlShowClientl));
    }
    return {
        GetClient
    }
}