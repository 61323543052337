import axios from "axios";
export default function AfficheCommandeClient(){

    const urlShowCommandeClient= `${process.env.REACT_APP_PHP_APP_URL}/admin/client/commande`;
    const GetCommandeClient=()=>{
        return (axios.get(urlShowCommandeClient));
    }
    return {
        GetCommandeClient
    }
}
