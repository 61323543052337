import { Link } from "react-router-dom";
import { Utilisateure } from "../../Modeles/UtilisateureModel";
import "./connexion.css";
import { useState, useEffect } from "react";
import ScrollReveal from "scrollreveal";

const Connexion: React.FC<Utilisateure> = ({
  handleSubmit,
  setCompteEmail,
  CompteEmail,
  setPassword,
  Password,
  message,
}) => {
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    const sr = ScrollReveal({
      distance: '100px',
      duration: 1800,
      reset: true,
    });

    sr.reveal('h1', { delay: 50, origin: 'top' });
    sr.reveal('.para-cnx', { delay: 60, origin: 'left' });
    sr.reveal('.login100-form-title', { delay: 200, origin: 'right' });



    

  }, []);

  const togglePasswordVisibility = () => {
    setShowPass(!showPass);
  };

  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css" />
      <div className="d-flex h-100">
        <div className="w-75 d-flex justify-content-center align-items-center login100-more">
          <div className="col text-center item-center">
            <div className="name-app-cnx text-white">
              <h1>MERCHSENTRY</h1>
            </div>
            <div className="para-cnx">
              <p className="text-white text-center">
                libérer l'efficacité : la ou la précision <br />
                mécanique rencontre le contrôle numérique
              </p>
            </div>
          </div>
        </div>

        <div className="w-25 h-100">
          <form onSubmit={handleSubmit} className="login100-form validate-form w-100">
            <span className="login100-form-title p-b-43">
              Connexion
              <i className="line-cnx bi bi-dash-lg"></i>
            </span>
            <div className="container">
              {message!="" && (
                <div className="alert alert-danger" role="alert">
                  <b className="text-center">
                    <i className="bi bi-question-circle-fill"></i> {message}
                  </b>
                </div>
              )}
              <p></p>
            </div>
            <label htmlFor="" className="mb-3">Pseudo</label>
            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
              <input
                onChange={(e) => setCompteEmail(e.target.value)}
                className="input100"
                type="text"
                name="CompteEmail"
                value={CompteEmail}
              />
              <span className="focus-input100" />
            </div>
            <label htmlFor="" className="mb-3">Mot de passe</label>
            <div className="wrap-input100 validate-input" data-validate="Password is required">
              <input
                onChange={(e) => setPassword(e.target.value)}
                className="input100"
                type={showPass ? "text" : "password"}
                id="pass"
                name="Password"
                value={Password}
              />
              <i
                onClick={togglePasswordVisibility}
                id="pass-icon"
                className={`eyes-icon bi ${showPass ? "bi-eye-fill" : "bi-eye-slash-fill"}`}
              ></i>
              <span className="focus-input100" />
            </div>
            <div className="container-login100-form-btn">
              <button type="submit" className="login100-form-btn">
                Entrer
              </button>
            </div>
            <div className="text-center mt-3 p-t-46 p-b-20">
              <Link to="/visiteurs">
              <span className="txt2">                 
              <a href="">                   
              Rejoindre comme visiteur <i className="bi bi-arrow-right-circle-fill" />
              </a>               
                         
              </span> 
              </Link>
              <br />
              <span className="txt2-1">                 
              <Link to="create-magasin">
              <i className="bi bi-box-arrow-right" /> créer votre magasin 
              </Link>                 
              </span> 
            </div>
            <div className="login100-form-social flex-c-m">
              <a href="#" className="login100-form-social-item flex-c-m bg1 m-r-5">
                <i className="fa fa-facebook-f" aria-hidden="true" />
              </a>
              <a href="#" className="login100-form-social-item flex-c-m bg2 m-r-5">
                <i className="fa fa-twitter" aria-hidden="true" />
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Connexion;
