import axios from "axios";
import { useParams } from "react-router-dom";

export default function AfficheCommandeByMagasin(id:any){
    const urlShowCommande= `${process.env.REACT_APP_PHP_APP_URL}/magasins/${id}/historique`;
    const GetCommande=()=>{
        return (axios.get(urlShowCommande));
    }
    return {
        GetCommande
    }
}