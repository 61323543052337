import { Link } from "react-router-dom";
import "../../Commercial/Produit/AfficheProduit.css";
import IMGProduit from "../../Admin/IMG/1.png";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { useContext, useEffect, useState } from "react";
import AfficheArticleInClient from "../../../Services/Magasin/AfficheArticleInClient";
import SidBarClient from "./SideBarClient";
import { MagasinContext } from "../../../Context/MagasinContext";

export interface ProductType {
  product: ArticleInfo[];
  messageErros: string;
}

export default function AllProductClient() {
  const magasinContext = useContext(MagasinContext);
  const ClientId = localStorage.getItem('ClientId');
  const ClientName = localStorage.getItem('ClientName');
  const idClient = ClientId || magasinContext.id?.id;
  const NameClient =  ClientName || magasinContext.id?.NomMagasin;

  const [search, setSearch] = useState("");
  const [state, setState] = useState<ProductType>({
    product: [] as ArticleInfo[],
    messageErros: "Aucun produit trouvé",
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.trim()); // Apply trim() to remove leading and trailing whitespace
  };

  useEffect(() => {
    if (!idClient) return; // Ensure idClient is defined before making the API call

    AfficheArticleInClient()
      .getArticle()
      .then((res) => setState({ ...state, product: res.data }))
      .catch((err) => setState({ ...state, messageErros: err.message }));
  }, [idClient, state]);

  const { product, messageErros } = state;

  return (
    <>
      <SidBarClient />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
      <div className="allproductCommercial categ">
        <div className="container_div mt-5" style={{ width: '100vw' }}>
          <div className="" style={{ display: 'flex', marginLeft: '-4%', alignItems: "center" }}>
            <div className="" style={{ width: '83vw' }}>
              <div className="position-relative">
                <i className="fa fa-search fa-2x position-absolute" style={{ color: "#C5C5C5", top: '50%', fontSize: "1.4rem", transform: 'translateY(-50%)', left: '10px' }} />
                <input type="text" onChange={handleSearch} className="form-control form-input pl-4" placeholder="Rechercher un produit, ref .." />
                <i className="bi bi-sliders fa-2x position-absolute" style={{ fontSize: "1.4rem", color: "#C5C5C5", top: '50%', transform: 'translateY(-50%)', right: '10px' }} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {product && product.length > 0 ? (
            product
              .filter((pro) => {
                const searchTerm = search.toLowerCase().trim();
                const designation = pro.Designation.toLowerCase().trim();
                const refArticle = pro.RefArticle?.toString().toLowerCase().trim();
                return searchTerm === "" || designation.includes(searchTerm) || refArticle?.startsWith(searchTerm);
              })
              .map((pro) => (
                <div className="col-lg-3 col-md-6 col-12" key={pro.IdArticle}>
                  <Link className="gotoshow" to={`/clients/${idClient}/articles/${pro.IdArticle}`}>
                    <div className="box boxcomercial">
                      <div className="slidImages bar1">
                        <img src={IMGProduit} alt="" />
                        <p>{pro.Designation}</p>
                      </div>
                      <div className="bar2">
                        <p>Reference : <span>{pro.RefArticle ? pro.RefArticle : "N/A"}</span></p>
                      </div>
                      <div className="bar3-client">
                        <div className="price"><p>{pro.PrixVenteArticleTTC} MAD <span>/ P.U</span></p></div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
          ) : (
            <div className="no-produit">
              <i className="bi bi-emoji-neutral"></i><br />
              <p>Malheureusement, aucun produit trouvé pour l'instant.</p><br />
              <a href="https://api.whatsapp.com/send?phone=212661718081" target="_blank" className="botton-remplir"><button><i className="bi bi-whatsapp"></i>Contactez-nous</button></a>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
