import { useState, useEffect } from 'react';
// import { TimerContext } from './TimerContext';

const PanierChrono = () => {
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(0);
    const [days, setDays] = useState(0);
    const [isRunning, setIsRunning] = useState(false); // Destructure setIsRunning from context
    const StartDate = localStorage.getItem('StartDate');
    const productVisiteur = localStorage.getItem('productVisiteur');
    
    useEffect(() => {
        if (productVisiteur && productVisiteur.length === 0) {
            localStorage.removeItem('StartDate');
            
        }
    }, [productVisiteur]);
    useEffect(() => {
        const savedStartDate = localStorage.getItem('StartDate');
        if (savedStartDate) {
            const startDate = new Date(savedStartDate);
            const currentDate = new Date();
            const elapsedTime = (currentDate - startDate) / 1000;

            const elapsedDays = Math.floor(elapsedTime / (3600 * 24));
            const elapsedHours = Math.floor((elapsedTime % (3600 * 24)) / 3600);
            const elapsedMinutes = Math.floor((elapsedTime % 3600) / 60);
            const elapsedSeconds = Math.floor(elapsedTime % 60);

            setDays(elapsedDays);
            setHours(elapsedHours);
            setMinutes(elapsedMinutes);
            setSeconds(elapsedSeconds);

            if (currentDate - startDate < 30 * 24 * 3600 * 1000) {
                setIsRunning(true);
            } else {
                localStorage.removeItem('startDate');
            }
        }
    }, [setIsRunning]);
    useEffect(() => {
        let timer;
        if (isRunning) {
            timer = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds + 1);
            }, 1000);
        }

        if (seconds === 60) {
            setSeconds(0);
            setMinutes(prevMinutes => prevMinutes + 1);
        }

        if (minutes === 60) {
            setMinutes(0);
            setHours(prevHours => prevHours + 1);
        }

        if (hours === 24) {
            setHours(0);
            setDays(prevDays => prevDays + 1);
        }

        return () => clearInterval(timer);
    }, [isRunning, seconds, minutes, hours, setIsRunning]); // Ensure setIsRunning is in the dependency array

    const handleStartTimer = () => {
        localStorage.setItem('StartDate', new Date().toISOString());
        setIsRunning(true);
        setSeconds(0);
        setMinutes(0);
        setHours(0);
        setDays(0);
    };

    const stopTimer = () => {
        setIsRunning(false);
    };

    const resetTimer = () => {
        setIsRunning(false);
        setSeconds(0);
        setMinutes(0);
        setHours(0);
        setDays(0);
        localStorage.removeItem('StartDate');
    };

    return (
        <>
            {
                StartDate && (
                    <div >
                        <div className="d-flex " style={{ position:'fixed',right:'0',zIndex:'10',backgroundColor:'yellow',bottom:'100px' ,borderRadius:'10px' ,padding:'10px',marginRight:'5%'}}>
                            <div>
                                <div className=' display-6'>{days} : {hours} : {minutes} : {seconds}</div>
                                <button hidden={true} onClick={handleStartTimer} disabled={isRunning}>Start</button>
                            </div>
                        </div>
                    </div>
                )
            }



        </>
    );
};

export default PanierChrono;
