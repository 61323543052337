import axios from "axios";
import { useParams } from "react-router-dom";
export default function GetClientlById(id:any,idm:any){
    const urlShowCommercial= `${process.env.REACT_APP_PHP_APP_URL}/magasin/${idm}/client/${id}`;
    const GetClient=()=>{
        return (axios.get(urlShowCommercial));
    }
    return {
        GetClient
    }
}