import { Link } from "react-router-dom";
import "./ChangePassword.css"
import BarDeretourSetting from "../../../components/BarDeretourSetting";
import SideBareCommercial from "../SideBareCommercial/SideBareCommercial";
export default function ChangePasswordCommercial(){
    return <>
    <SideBareCommercial/>
    <div className="container mt-5">
    <BarDeretourSetting/>
      <div className="card-password">
<h5 className="">Veuillez changer votre mot de passe ici <i className="bi bi-shield-lock"></i></h5>
    <div className="col-7">
    <span>Ancien mot de passe</span>
      <input type="text"  name="nom" className="form-control"/>
    </div>
    
    <div className="col-7">
    <span>Nouveau mot de passe</span>

      <input type="text"  name="prenom"  className="form-control" />
    </div>
    <div className="col-7">
    <span>Confirmation mot de passe</span>

      <input type="text"  name="prenom"  className="form-control" />
    </div>
    <br />
      <Link to={""} className="btn-changer">Change</Link>
    <Link to={""} className="autre-methode">Choisi autre methode </Link>
    </div>
    </div>
    </>
}