import { Link } from "react-router-dom";
import SideBareMagasin from "../SideBareMagasin/SideBareMagasin";
import "./notification.css"
import "./responsiveNotification.css"
import { useContext, useEffect, useState } from "react";
import { MagasinContext } from "../../../Context/MagasinContext";
import GetNotification from "../../../Services/Magasin/GetNotification";
import { NotificationMagasinType } from "../../../Modeles/NotificationModel";
export interface Notification{

    Notification : NotificationMagasinType[],
    messageErros:string,
  }
export default function NotificationMagasin(){
    const magasinContext = useContext(MagasinContext);
    const MagasinId = localStorage.getItem('MagasinId');
    const panier = localStorage.getItem('paniermagasin');
      const id = MagasinId || magasinContext.id?.id;

    const [state , setState] = useState<Notification>({
      Notification:[] as NotificationMagasinType[],
      messageErros : "accune Notification",
  
  })
  useEffect(()=>{
      setState({...state })
          GetNotification().getNotif()
          .then((res)=>setState({...state  , Notification:res.data})
          )
          .catch(msg=>setState({...state  , Notification:msg.messageErros}))
  },[]);
  const { Notification, messageErros } = state
console.log(Notification);
    return <>
        <SideBareMagasin/>
        <div className="container containerNotification">
            <h2 className="mt-5 mb-5">Notifications</h2>
            {Notification && Notification.length >0 ? Notification.map(notif=>(

            <Link className="derection" to="/magasins/:id/notifications/:id/confirme">
                <h5>{notif.Date}</h5>
            <div className="card mb-5">
                <span>{notif.Notification_Title}</span>
                <div className="message">
                <p>
                {notif.Notification_Content}
                                </p>
                <i className="bi bi-arrow-up-right-circle-fill"></i>
                </div>
                <div className="icon">
                </div>
            </div>
            </Link>
            )):<h4>Accune Notification</h4>
        
        }

        </div>
    </>
}