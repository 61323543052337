import axios from "axios";

export default function ArticlesByIdUtilisateur(id:any){
    const urlShowCommercial= `${process.env.REACT_APP_PHP_APP_URL}/visiteur/articles/${id}`;
    const GetArticle=()=>{
        return (axios.get(urlShowCommercial));
    }
    return {
        GetArticle
    }
}