import axios from "axios";

export default function GetNotification(){
    
    const MagasinId = localStorage.getItem('MagasinId'); 
    const urlGetNotification = `${process.env.REACT_APP_PHP_APP_URL}/magasins/${MagasinId}/notification`;
    const getNotif=()=>{
        return (axios.get(urlGetNotification));
    }
    return {
        getNotif
    }
}