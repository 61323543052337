import produit from "../../Admin/IMG/1.png"
import { Link } from "react-router-dom";
// import "./afficheProduitmagasin.css"
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import { useContext, useEffect, useState } from "react";
import { MagasinContext } from "../../../Context/MagasinContext";
import SideBareCommercial from "../SideBareCommercial/SideBareCommercial";


interface Product {
    id: string;
    name: string;
    imageUrl: string;
    price: string;
    quantity: number;
}

const AfficheProduitByIdCommercial: React.FC<ArticleInfo> = ({
    IdArticle,
    Designation,
    PrixVenteArticleTTC,
    Description,
    image,
    stock,
    quantité,
    RefArticle,
    LibelleSubstitut,
    prix_ht_2_magasin,
    prix_ht_3_magasin,
    prix_ht_1_magasin,

}) => {
    const [products, setProducts] = useState<Product[]>([]);
    const [value, setValue] = useState(1);
    const [message, setMessage] = useState('');
    // const id:any = localStorage.getItem('MagasinId');
    const handleGoBack = () => {
        window.history.back();
    };
    const magasinContext = useContext(MagasinContext);
    const MagasinId = localStorage.getItem('CommercialId');
    const CommercialName = localStorage.getItem('CommercialName');
    const id = MagasinId || magasinContext.id?.id;
    const nameMagasin = CommercialName || magasinContext.id?.nom


    const addProductToPanier = (id: string, image: string, name: string, price: string) => {
        localStorage.setItem('StartDate',new Date().toISOString())
        setProducts((prevProducts: Product[]) => {
            const newProduct: Product = { id, name, imageUrl: image, price, quantity: value };
            if (newProduct.quantity != 0) {
                let arr: Product[] = [];
                const localStorageData = localStorage.getItem('productsCommercial');
                if (localStorageData) {
                    arr = JSON.parse(localStorageData);
                }
                const productIndex = arr.findIndex((product: Product) => product.id === id);
                if (productIndex !== -1) {
                    arr[productIndex].quantity = Number(arr[productIndex].quantity) + Number(value);
                } else {
                    arr.push(newProduct);
                }
                localStorage.setItem('productsCommercial', JSON.stringify(arr));
                setMessage('product added to panier');
                setTimeout(() => {
                    setMessage('');
                }, 3000);
            }
            return [];
        });
    };


    return (<>
        <SideBareCommercial />
        <div className="container produitCard mt-5">
            <i onClick={handleGoBack} className="bi bi-arrow-left-short"></i>
            <br />
            {
                message && (
                    <div className="bg-success text-light p-2 my-2 rounded">
                        {message}
                    </div>)
            }

            <br />
            <div className="barTitle paniercommercial">
                <h3>{Designation}</h3>
                <Link style={{ background: 'none' }} to={`/commercials/${id}/panier`} >
                    <i className="bi bi-cart3 icon"><span>1</span></i>
                </Link>
            </div>
            <div className="cardItem mt-5">
                <img src={produit} alt="" />
                <div className="ItemsInfo">
                    <h2 className="mb-5">TTC : {PrixVenteArticleTTC} MAD</h2>
                    <div className="3prix mb-5">
                        <h6>prix HT 1 : <span>{prix_ht_1_magasin}</span></h6>
                        <h6>prix HT 2 : <span>{prix_ht_2_magasin}</span></h6>
                        <h6>prix HT 3 :<span>{prix_ht_3_magasin}</span></h6>
                        <h5>Substitut : <span>{LibelleSubstitut ? LibelleSubstitut : "N/A"}</span></h5>

                    </div>
                    <div className="quantity">
                        <h6>Quantité <input className="inputQ" max={quantité} type="number" min={1} value={value} onChange={(e: any) => setValue(e.target.value)} /> </h6>
                    </div>
                    <button className="ajouterP" onClick={() => addProductToPanier(RefArticle, produit, Designation, PrixVenteArticleTTC)} >Ajouter au panier</button>
                </div>

            </div>
            <div className="sectionDesc">
                <h5>Réference : <span>{RefArticle}</span></h5>
            </div>
        </div>
    </>)
}
export default AfficheProduitByIdCommercial;