import produit from "../../Admin/IMG/1.png"
import { Link } from "react-router-dom";
// import "./afficheProduitmagasin.css"
import "./responsiveAfficheProduitClient.css"
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import SidBarClient from "./SideBarClient";
import { useState } from "react";

interface Product {
    id: string;
    name: string;
    imageUrl: string;
    price: string;
    quantity: number;
}

const AfficheProduitClient: React.FC<ArticleInfo> = ({
    IdArticle,
    Designation,
    PrixVenteArticleTTC,
    Description,
    image,
    stock,
    quantité,
    RefArticle,
    LibelleSubstitut,
    prix_ht_2_magasin,
    prix_ht_3_magasin,
    prix_ht_1_magasin,

}) => {
    const handleGoBack = () => {
        window.history.back();
    };
    const [products, setProducts] = useState<Product[]>([]);
    const [value, setValue] = useState(1);
    const [message, setMessage] = useState('');
    const id = localStorage.getItem('MagasinId');
    const addProductToPanier = (id: string, image: string, name: string, price: string) => {
        localStorage.setItem('StartDate',new Date().toISOString())
        setProducts((prevProducts: Product[]) => {
            const newProduct: Product = { id, name, imageUrl: image, price, quantity: value };
            if (newProduct.quantity != 0) {
                let arr: Product[] = [];
                const localStorageData = localStorage.getItem('productsClient');
                if (localStorageData) {
                    arr = JSON.parse(localStorageData);
                }
                const productIndex = arr.findIndex((product: Product) => product.id === id);
                if (productIndex !== -1) {
                    arr[productIndex].quantity = Number(arr[productIndex].quantity) + Number(value);
                } else {
                    arr.push(newProduct);
                }
                localStorage.setItem('productsClient', JSON.stringify(arr));
                setMessage('product added to panier');
                setTimeout(() => {
                    setMessage('');
                }, 3000);
            }
            return [];
        });
    };
    return (<>
        <SidBarClient />
        <div className="container produitCard mt-5">
            <Link to="" onClick={handleGoBack}><i className="bi bi-arrow-left-short"></i></Link>
            <br />
            {
                message && (
                    <div className="bg-success text-light p-2 my-2 rounded">
                        {message}
                    </div>)
            }
            <br />

            <div className="barTitle panier-client">
                <h3>{Designation}</h3>
                <Link style={{background:'none'}} to={`/client/${id}/panier`}><i className="bi bi-cart3 icon"><span className="pcount">1</span></i></Link>
            </div>
            <div className="cardItem mt-5">
                <img src={produit} alt="" />
                <div className="ItemsInfo">
                    <h2 className="mb-5">Ttc : {PrixVenteArticleTTC} MAD</h2>
                    <div className="quantity">
                        <h6>Quantité <input className="inputQ" value={value} onChange={(e: any) => setValue(e.target.value)} type="number" min={1} /> </h6>
                    </div>
                    <button className="ajouterP-client" onClick={() => addProductToPanier(RefArticle, produit, Designation, PrixVenteArticleTTC)} >Ajouter au panier</button>
                </div>

            </div>
            <div className="sectionDesc">
                <h5>Réference : <span>{RefArticle}</span></h5>
            </div>
        </div>
    </>)
}
export default AfficheProduitClient;