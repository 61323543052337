import { Link, useLocation } from "react-router-dom"
import"./SideBare.css"
import profil from "../../Admin/IMG/Commercial.png"
import logo from "../../Admin/IMG/cropped-favicon-mylittlegarage-32x32 1.png"
import { useContext, useEffect, useState } from "react";
import { MagasinContext } from "../../../Context/MagasinContext";
export default function SideBareCommercial(){
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const magasinContext = useContext(MagasinContext);
  const MagasinId = localStorage.getItem('CommercialId');
  const CommercialName = localStorage.getItem('CommercialName');
    const id = MagasinId || magasinContext.id?.id;
    const nameMagasin =  CommercialName || magasinContext.id?.nom;
  useEffect(() => {
    const path = location.pathname;

    setActiveItem(path);
  }, [location.pathname]);

    return<>
<div>
  <meta charSet="UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossOrigin="anonymous" />
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css" />	{/* My CSS */}
  <title>Matjar</title>
</div>

<body>
<section className="sidebar">
  <img className="profilCommercial mb-3 mt-4" src={profil}  />	
    <h5 style={{fontSize:'1.2rem'}} className="text-white mt-2 mx-4">{CommercialName&& CommercialName}</h5>
  <br />	
  <ul className="side-menu top mb-5">
    <li className={activeItem === `/commercials/${id}` ? "active" : "" }>
        <Link className="active" to={`/commercials/${id}`}>
      <a href="#">
        <i className="bi bi-menu-button-wide-fill" />
      </a>
      </Link>
    </li >
    <li className={activeItem === `/commercials/${id}/articles` ? "active" : "" }>
      <Link to={`/commercials/${id}/articles`}>

      <a href="#">
      <i className="bi bi-box-seam" />

            </a>
      </Link>
    </li>
    <li className={activeItem === `/commercials/${id}/historique` ? "active" : ""}>
              <Link to={`/commercials/${id}/historique`}>
                <a href="#">
                  <i className="bi bi-clock"></i>
                </a>
              </Link>
            </li>
  </ul>
  <br />
  <div className="menu-down-commercial mt-5">
  <div className="hr">

  <hr />
  </div>
    <ul className="side-menu-commercial top ">
    <li className={activeItem === `/commercials/${id}/notification` ? "active" : "" }>
    <Link to={`/commercials/${id}/notification`}>

      <a href="#">
      <i className="bi bi-bell"></i>
            </a>

      </Link>
    </li>
      <li className={activeItem === `/commercials/${id}/paramétres`? "active" : "" }>
      <Link to={`/commercials/${id}/paramétres`}>

        <a href="#">
          <i className="bi bi-gear" />
        </a>

      </Link>
      </li>
    </ul>

    <ul className="logout-commercial">
      <li className={activeItem === "/" ? "active" : "" }> 
      <Link to={`/logout`}>

        <a href="#" className="logout">
          <i className="bi bi-box-arrow-left" />
        </a>

      </Link>
      </li>
    </ul>
  </div>
</section>
<div className="container">
        <Link className=" icon-assi" to={`/commercials/${id}/assistance`}><i className="bi bi-headset"></i></Link>
      
      </div>
  <div className=" text-center mt-4 pass-commande-commercial">
    <Link to={`/commercials/${id}/commande`}>
    <button className="btn-pass-commande-commercial">Passe une commande</button>
    </Link>
  </div>
</body>

    </>
}