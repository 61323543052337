import { Link } from "react-router-dom";
// import Sidebare from "../Sidbare/Sidebare";
import "./historique.css"
import { useEffect, useState } from "react";
import HistoriqueCommande from "../../../Services/Admin/HistoriqueCommande";
import { Commande, CommandeCommercial } from "../../../Modeles/Commande";
import SideBareCommercial from "../SideBareCommercial/SideBareCommercial";
import AfficheCommandeCommercial from "../../../Services/Admin/AfficheCommandeCommercial";
export interface commandeType{

  product : CommandeCommercial[],
  messageErros:string,
}
export default function HistoriqueCommandeCommercial(){
  const [state , setState] = useState<commandeType>({
    product: [] as CommandeCommercial[],
    messageErros : "Aucune commande",
  });

const itemsPerPage = 5;
const [currentPage, setCurrentPage] = useState(1);

const handleChangePage = (pageNumber: number) => {
  setCurrentPage(pageNumber);
};

useEffect(()=>{
    setState({...state })
    AfficheCommandeCommercial().GetCommandeCommercial()
        .then((res)=>setState({...state  , product:res.data})

        )
        .catch(msg=>setState({...state  , product:msg.messageErros}))
},[]);
const {product , messageErros} = state
console.log(product);
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = product && product.length > 0 ? product.slice(indexOfFirstItem, indexOfLastItem) : [];



const pageNumbers = [];
if (Array.isArray(product)) {
  for (let i = 1; i <= Math.ceil(product.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
}

    return<>
    <SideBareCommercial/>
    <div className="container mt-5">

    <h4>Historique des commandes</h4>
    <div className="filtreElement">
    <Link to="" className="btnFilterS"><a>Cette semaine</a></Link>
    <Link to="" className="btnFilterM"><a >Ce mois </a></Link> 
    </div>
    <div className="tableInfo">
  <table className="table table-bordered">
    <thead>
      <tr>
        <th scope="col" className="ncom">N de commande</th>
        <th scope="col">Montant</th>
        <th scope="col">Statut</th>
        <th scope="col">details</th>
        <th scope="col">Date</th>

      </tr>
    </thead>
    <tbody>
    {currentItems && currentItems.length > 0 ? currentItems.map((pro, index) =>(
      <tr key={index}>
        <td>{pro.id}</td>
        <td>{pro.Total} MAD</td>
        <td style={{ color: pro.Statut === 'confirme' ? 'green' : pro.Statut === 'annuler' ? 'red' : 'black' }}>{pro.Statut}</td>
        <td><Link className="vdbtn" to={``}>Voir detail <i className ="bi bi-eye-fill"></i></Link></td>
        <td>{pro.Date}</td>
      </tr>
      )):<h5 className="mt-5 text-center">Pas de commande </h5>
    }
    </tbody>
  </table>
  {pageNumbers.length > 1 && (

<nav>
<ul className="pagination">
  <li className="page-itemC">
    <a href="#" className="page-link" onClick={() => handleChangePage(currentPage - 1)}>Précédent</a>
  </li>
  {pageNumbers.map((number) => (
    <li className={`page-item ${currentPage === number ? 'active' : ''}`} key={number}>
      <a href="#" className="page-link" onClick={() => handleChangePage(number)}>{number}</a>
    </li>
  ))}
  
  <li className="page-item">
    <a href="#" className="page-link" onClick={() => handleChangePage(currentPage + 1)}>Suivant</a>
  </li>
</ul>
</nav>
)}
</div>


</div>

        
    </>
}