import { Link } from "react-router-dom";

import "./panierClient.css";
import { useState, useEffect } from "react";
import SidBarClient from "../SideBarClient";
import PanierChrono from "../../../../components/PanierChrono";


interface Product {
    id: string;
    name: string;
    imageUrl: string;
    price: string;
    quantity: number;
}

const deleteProduct = (id: string, updateProducts: (products: Product[]) => void) => {
    let arr: Product[] = [];
    const localStorageData = localStorage.getItem('productsClient');
    if (localStorageData) {
        arr = JSON.parse(localStorageData);
    }

    // Correctly filter out the product with the given ID
    const updatedProducts = arr.filter(product => product.id !== id);
    console.log(updatedProducts);

    localStorage.setItem('productsClient', JSON.stringify(updatedProducts));
    updateProducts(updatedProducts);
};





export default function PanierClient() {
    const [products, setProducts] = useState<Product[]>([]);
    const [isDivVisible, setDivVisible] = useState(true);

    const [Totale, setTotale] = useState<number>(0);

    useEffect(() => {
        const storedProducts = localStorage.getItem('productsClient');
        if (storedProducts) {
            try {
                setProducts(JSON.parse(storedProducts));
            } catch (error) {
                console.error('Error parsing products from local storage', error);
            }
        }
    }, []);

    const handleDeleteProduct = (id: string) => {
        deleteProduct(id, setProducts);
    };


    const [message, setMessage] = useState('');

    const hundelVidePanier = () => {
        localStorage.clear();
        localStorage.removeItem('productsClient');
        setDivVisible(false);
        setProducts([]);
        setMessage('Panier vidé avec succès!');
        setTimeout(() => {
            setMessage('');
        }, 3000);
    }


    const sumQty = () => {
        let total = 0;
        for (let i = 0; i < products.length; i++) {
            const QtyXprice = Number(products[i].price) * Number(products[i].quantity);
            total += QtyXprice;
        }
        setTotale(total);
    };

    useEffect(() => {
        sumQty();
    }, [products]);


    return (
        <>
            <SidBarClient />
            
            <div className=" container mt-5">     
            <PanierChrono /> 
                <div className=" w-full d-flex justify-content-between align-items-center ">
                    <div>
                        <h2>Mon panier ({products.length})</h2>
                    </div>
                    <div>
                        <button onClick={() => hundelVidePanier()} className=" btn "> Vider le panier</button>
                    </div>
                </div>
                <div className="container mt-5">
                    {message && (
                        <div className="mt-4 p-2 bg-danger text-light rounded w-full">
                            {message}
                        </div>
                    )}
                </div>

                {(isDivVisible && products) && products.length > 0 ? (
                    products.map((item, index) => (
                        <div key={index} className="card mt-5">
                            <div className="InfoPrp">
                                <button
                                    className="supprimer"
                                    onClick={() => handleDeleteProduct(item.id)}
                                >
                                    Supprimer ce produit <i className="bi bi-x-lg"></i>
                                </button>
                                <h4>{item.price}</h4>
                            </div>
                            <div className="infoPP">
                                <img src={item.imageUrl} alt={item.name} />
                                <h5>{item.name}</h5>
                                <div className="quantite">
                                    <label>
                                        Quantité :
                                        <input type="number" min={1} value={item.quantity} readOnly />
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No products available.</p>
                )}
                <div className="w-75 d-flex mx-auto align-items-center h-25" style={{ "height": '100px' }} >
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="mr-3">
                            Totale :
                        </div>
                        <div>
                            <h5>{Totale} MAD</h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
