import { Link } from "react-router-dom";
import "./notification.css"
import SideBareCommercial from "../SideBareCommercial/SideBareCommercial";
export default function NotificationCommercial(){
    return <>
        <SideBareCommercial/>
        <div className="container">
            <h2 className="mt-5 mb-5">Notifications</h2>
            <Link className="derectionCommercial" to="">
                <h5>Aujourd'hui</h5>
            <div className="card mb-5">
                <span>03/05/2024</span>
                <div className="message">
                <p>
                Voici un nouveau article "POCHETTE RODAGE 3114"
                </p>
                <i className="bi bi-arrow-up-right-circle-fill"></i>
                </div>
                <div className="icon">
                </div>
            </div>
            </Link>
            <Link className="derectionCommercial" to="">
                <h5>Cette semaine</h5>
            <div className="card">
                <span>1/12/2023</span>
                <div className="message">
                <p>
                Voici un nouveau article "SENSOR"
                </p>
                <i className="bi bi-arrow-up-right-circle-fill"></i>
                </div>
                <div className="icon">
                </div>
            </div>
            </Link>
        </div>
    </>
}