import axios from "axios";

export default function DemandesAdmin(){
    const urlShowCommercial= `${process.env.REACT_APP_PHP_APP_URL}/admin/demandes`;
    const GetCommande=()=>{
        return (axios.get(urlShowCommercial));
    }
    return {
        GetCommande
    }
}