// DetailCommande.js
import { Link, useParams } from "react-router-dom";
import Sidebare from "../Sidbare/Sidebare";
import { useEffect, useState } from "react";
import { Commande, detailcommande } from "../../../Modeles/Commande";
import DetailCommandeService from "../../../Services/Admin/DetailCommandeService";

export interface CommandeType {
    product: detailcommande[];
    messageErrors: string;
}

export default function DetailCommande() {
    const { id } = useParams(); // Retrieving the id parameter from the URL
    const [state, setState] = useState<CommandeType>({
        product: [] as detailcommande[],
        messageErrors: "Aucune commande",
    });

    useEffect(() => {
        // Fetch data using the id parameter
        const fetchData = async () => {
            try {
                const res = await DetailCommandeService(id).getCommande();
                setState({ ...state, product: res.data });
            } catch (error) {
                setState({ ...state, messageErrors: "error.message" });
            }
        };

        fetchData();
    }, [state]); // Dependency array includes id and state

    const { product, messageErrors } = state;
    const handleGoBack = () => {
        window.history.back();
    };
    return <>
    <Sidebare/>
    <div className="container mt-5">

    <h4 className="text-center">Details des commandes</h4>
  <div className="barRetour">
      <span className="iconRetour">
        <i onClick={handleGoBack} className="bi bi-arrow-left-short"></i>
      </span>

  </div>

    <div className="tableInfo mt-5">
  <table className="table table-bordered">
    <thead>
      <tr>
        <th scope="col" className="ncom">N de commande</th>
        <th scope="col">Article</th>
        <th scope="col">Prix</th>
        <th scope="col">Quantity</th>
        <th scope="col">Statut</th>
        <th scope="col">Date</th>
        <th scope="col">Action</th>


      </tr>
    </thead>
    <tbody>
    {product && product.length>0? product.map(pro=>(
      <tr>
        <td>{pro.idCommande}</td>
        <td>{pro.NomArticle}</td>
        <td>{pro.prix} MAD</td>
        <td>{pro.quantity}</td>
        <td>{pro.Statut}</td>
        <td>{pro.created_at}</td>
        <td><Link className="btn btn-success mx-2" to={``}>Confirmer </Link>
        <Link className="btn btn-danger" to={``}>Annuler</Link>
        </td>
      </tr>
      )):<h5 className="mt-5 text-center">Pas de commande </h5>
    }
    </tbody>
  </table>
</div>


</div>
</>
}
