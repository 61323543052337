import { Link } from "react-router-dom";
import SidBarClient from "./SideBarClient";
import "./homeclient.css";
import catProduit from "../../Admin/IMG/1.png";
// import logovisa from "./logo visa.png";
import logosimvisa from "./logo plusVisa.png";
import 'swiper/css/free-mode';
import 'swiper/css/autoplay';
import { Autoplay, FreeMode } from 'swiper/modules';
import ImageProduit3 from "../../Admin/IMG/3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { useContext, useEffect, useState } from "react";
import AfficheArticleInMagasin from "../../../Services/Magasin/AfficheArticleInMagasin";
import { MagasinContext } from "../../../Context/MagasinContext";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import AfficheArticleInClient from "../../../Services/Magasin/AfficheArticleInClient";

export interface ProductType {
  product: ArticleInfo[],
  messageErros: string,
}

interface Product {
  id: string;
  name: string;
  imageUrl: string;
  price: string;
  quantity: number;
}

export default function HomeClient() {
  const [products, setProducts] = useState<Product[]>([]);
  const [isDivVisible, setDivVisible] = useState(true);
  const [Totale, setTotale] = useState<number>(0);
  const [message, setMessage] = useState('');
  const magasinContext = useContext(MagasinContext);
  const ClientId = localStorage.getItem('ClientId');
  const ClientName = localStorage.getItem('ClientName');
  const idClient = ClientId || magasinContext.id?.id;
  const NameClient = ClientName || magasinContext.id?.NomMagasin;
  const [search, setSearch] = useState("");
  const [state, setState] = useState<ProductType>({
    product: [] as ArticleInfo[],
    messageErros: "accune produit",
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.trim()); // Apply trim() to remove leading and trailing whitespace
  };

  const deleteProduct = (id: string, updateProducts: (products: Product[]) => void) => {
    let arr: Product[] = [];
    const localStorageData = localStorage.getItem('productsClient');
    if (localStorageData) {
      arr = JSON.parse(localStorageData);
    }
    const updatedProducts = arr.filter(product => product.id !== id);
    localStorage.setItem('productsClient', JSON.stringify(updatedProducts));
    updateProducts(updatedProducts);
  };

  const sumQty = () => {
    let total = 0;
    for (let i = 0; i < products.length; i++) {
      const QtyXprice = Number(products[i].price) * Number(products[i].quantity);
      total += QtyXprice;
    }
    setTotale(total);
  };

  useEffect(() => {
    sumQty();
  }, [products]);

  useEffect(() => {
    const storedProducts = localStorage.getItem('productsClient');
    if (storedProducts) {
      try {
        setProducts(JSON.parse(storedProducts));
      } catch (error) {
        console.error('Error parsing products from local storage', error);
      }
    }
  }, []);

  const handleDeleteProduct = (id: string) => {
    deleteProduct(id, setProducts);
  };

  const handleEmptyCart = () => {
    localStorage.removeItem('productsClient');
    setDivVisible(false);
    setProducts([]);
    setMessage('Panier vidé avec succès!');
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  useEffect(() => {
    setState({ ...state });
    AfficheArticleInClient().getArticle()
      .then((res) => setState({ ...state, product: res.data }))
      .catch(msg => setState({ ...state, product: msg.messageErros }));
  }, []);

  const { product, messageErros } = state;

  return (
    <>
      <SidBarClient />
      <div className="container">
        <h1 className="title-i-client">MERCHSENRTY</h1>
        <h4>Bienvenue, profitez de votre solde pour achetez tous ce qui vous avez besoin!</h4>
        <div className="row">
          <div className="col-9 col-70">
            <div className="mt-5">
              <div className="form forme">
                <i className="fa fa-search" />
                <input type="text" onChange={handleSearch} className="form-control form-input" placeholder="Recherchez un produit , ref .." />
                <span className="left-pan"><i className="bi bi-sliders"></i></span>
              </div>
            </div>
            <div className="equipement">
              <div className="bar-title-client">
                <h4>Equipements</h4>
                <Link to={`/clients/${idClient}/articles`} className="vp-client">Voir plus <i className="bi bi-arrow-right"></i></Link>
                <div className="pod" />
              </div>

              <Swiper
      freeMode={true}
      grabCursor={true}
      className="container myswiper"
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 80,
        },
        600: {
          slidesPerView: 3,
          spaceBetween: 80,
        },
        800: {
          slidesPerView: 3,
          spaceBetween: 5,
        },
      }}
      autoplay={{
        delay: 2000, 
        disableOnInteraction: false,
      }}
      modules={[Autoplay, FreeMode]}
    >
            {product && product.length > 0 ? (
              product
                .filter((pro) => {
                  const searchTerm = search.trim().toLowerCase();
                  const designation = pro.Designation.toLowerCase();
                  const refArticle = pro.RefArticle.toString().toLowerCase();
                  const sub = pro.LibelleSubstitut?.toString().toLowerCase();

                  const searchTerms = searchTerm.split(" ");

                  return searchTerms.every(
                    (term) =>
                      designation.includes(term) ||
                      refArticle.startsWith(term) ||
                      (sub && sub.startsWith(term))
                  );
                })
                .map((pro) => (
                  <SwiperSlide className="upBox" key={pro.IdArticle}>
                                  <Link className="upboxclient" to={`/clients/${ClientId}/articles/${pro.IdArticle}`}>

                      <div className="box">
                      {
                        pro.réduction && pro.réduction > 0 ? (
                          <span className="reduction">{pro.réduction}% de réduction</span>
                        ) : (
                          ""
                        )
                      }
                        <div className="slidImage">
                          <img src={ImageProduit3} alt="" />
                          <div className="overlay"></div>
                        </div>
                        <div className="detailBoxMagasin">
                          <div className="type">
                            <p className="paran">{pro.Designation}</p>
                            <i className="bi bi-cart-plus"></i>
                          </div>
                          <div className="price">
                            <p>
                              {pro.PrixVenteArticleTTC} MAD<span>/P.U</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))
            ) : (
              <div className="no-produit">
                <i className="bi bi-emoji-neutral"></i>
                <br />
                <p>
                  Malheureusement, on n‘a pas ce produit pour l’instant.
                </p>
                <br />
                <Link
                  to={"https://api.whatsapp.com/send?phone=212661718081"}
                  target="_blank"
                  className="botton-remplir"
                >
                  <button>
                    <i className="bi bi-whatsapp"></i>Contactez-nous
                  </button>
                </Link>
              </div>
            )}
          </Swiper>
            </div>
            <div className="row container mx-auto mt-5">
          {product && product.length > 0 ? (
            product
              .filter((pro) => {
                const searchTerm = search.toLowerCase();
                const designation = pro.Designation.toLowerCase();
                const refArticle = pro.RefArticle.toString().toLowerCase();
                const sub = pro.LibelleSubstitut?.toString().toLowerCase();
                return (
                  searchTerm === "" ||
                  designation.includes(searchTerm) ||
                  refArticle.startsWith(searchTerm) ||
                  sub?.startsWith(searchTerm)
                );
              })
              .map((pro) => (
                <div className="col-lg-4 col-md-3 col-12" key={pro.IdArticle}>
                    <Link className="upboxclient" to={`/clients/${ClientId}/articles/${pro.IdArticle}`}>

                    <div className="box">
                      <div className="slidImage">
                      {
                        pro.réduction && pro.réduction > 0 ? (
                          <span className="reduction">{pro.réduction}% de réduction</span>
                        ) : (
                          ""
                        )
                      }
                        <img src={ImageProduit3} alt="" />
                        <div className="overlay"></div>
                      </div>
                      <div className="detailBoxMagasin">
                        <div className="type">
                          <p className="paran">{pro.Designation}</p>
                          <i className="bi bi-cart-plus"></i>
                        </div>
                        <div className="price">
                          <p>
                            {pro.PrixVenteArticleTTC} MAD<span>/P.U</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
          ) : (
            <div className="no-produit">
              <i className="bi bi-info-lg"></i> Aucun produit
            </div>
          )}
        </div>
          </div>
          
          <div className="col-3 col-30">
            <div className="card-visa">
              {/* <img className="visa" src={logovisa} alt="" /> */}
              <div className="sim-visa">
                <img className="sim" src={logosimvisa} alt="" />
              </div>
              <div className="num-card">
                <h5><span>4000</span> <span>1234</span> <span>5678</span> <span>9012</span></h5>
              </div>
              <div className="d-card">
                <div className="df">
                  <h5>01/30</h5>
                </div>
                <div className="code-card">
                  <h5>123</h5>
                </div>
              </div>
              <h5 className="card-name">{NameClient}</h5>
            </div>
            <div className="progresse">
              <div className="progress" style={{ width: '25%', backgroundColor: '#FFC400' }} />
              <div className="solde">
                <h6>solde:</h6>
                <span>2 500 MAD / 10 000 MAD</span>
              </div>
            </div>
            <div className="card-client">
              <p>Mon Panier ({products.length})</p>
              {
                products && products.map((item) => (
                  <div key={item.id}>
                    <div className="card-content">
                      <img src={item.imageUrl} alt="" />
                      <h4>{item.name}</h4><br />
                      <Link to="" onClick={() => handleDeleteProduct(item.id)} className="button-supprimer-client">Supprimer ce produit <i className="bi bi-trash"></i></Link>
                    </div>
                    <div className="quantityAprix">
                      <input value={item.quantity} type="number" readOnly />
                      <h6>{item.price}</h6>
                    </div>
                  </div>
                ))
              }
              <div className="totalClient">
                <p>Total: {Totale} MAD</p>
                <Link className="scclient" to={""}>Suivi la commande</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
