// import { Link } from "react-router-dom";
import { ArticleInfo } from "../../../Modeles/ArticleModel";
import "./allproduitvisiteur.css";
import {useEffect, useState } from "react";
import React from 'react';
import AfficheProductsService from "../../../Services/Admin/AfficheProductsService";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import imgProduit from "./cassette-8-vitesses-12x32 1(1).png"
import logoBrand from "./Logo_Decathlon_RVB 1.png"
import { Link } from "react-router-dom";
export interface ProductType {
    product: ArticleInfo[];
    messageErrors: string;
}

export default function AllProductVisiteur() {
    const [search, setSearch] = useState("");
    const [state, setState] = useState<ProductType>({
        product: [] as ArticleInfo[],
        messageErrors: "aucun produit"
    });
    const [filteredProducts, setFilteredProducts] = useState<ArticleInfo[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 16;
    const [prevPage, setPrevPage] = useState(1);
    const handleChangePage = (pageNumber: number) => {
        const totalPages = Math.ceil(filteredProducts.length / recordsPerPage);
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            if (search === "") {
                setPrevPage(pageNumber);
            }
        }
    };
   
    const handleSearch = (e: any) => {
      setSearch(e.target.value.trim());
  };
    useEffect(() => {
        setState((prevState) => ({ ...prevState, messageErrors: "aucun produit" }));
        AfficheProductsService().getProduct()
            .then((res: any) => {
                const filtered = res.data.filter((pro: ArticleInfo) => {
                    const searchTerm = search.toLowerCase();
                    const designation = pro.Designation.toLowerCase();
                    const refArticle = pro.RefArticle.toString().toLowerCase();
                    const sub = pro.LibelleSubstitut?.toString().toLowerCase();
                    return searchTerm === "" ||
                        designation.includes(searchTerm) ||
                        refArticle.startsWith(searchTerm) ||
                        sub?.startsWith(searchTerm);
                });
                setFilteredProducts(filtered);
                setState((prevState: any) => ({ ...prevState, product: res.data }));
            })
            .catch((msg: any) => setState((prevState: any) => ({ ...prevState, messageErrors: msg.messageErrors })));
    }, [search]); 

    const { product, messageErrors } = state;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredProducts.slice(indexOfFirstRecord, indexOfLastRecord);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredProducts.length / recordsPerPage); i++) {
        pageNumbers.push(i);
    }
    return (
        <>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>

            <div className="visiteur">
        <Link to={"/visiteurs/panier"}>
            <div className="panier-visiteur-page" >
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
            <path style={{color:"black"}}  d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l.84 4.479 9.144-.459L13.89 4zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
            </svg>
            <span className="num">1</span>
            </div>
            </Link>

            <div className="title-app-page">MERCHSENTRY<br />
            <svg className="underline-title" width="149" height="5" viewBox="0 0 149 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.91077 0.821324C0.919613 0.821324 0.116124 1.62481 0.116124 2.61597C0.116124 3.60712 0.919613 4.41061 1.91077 4.41061V0.821324ZM146.679 4.41061H148.473V0.821324H146.679V4.41061ZM1.91077 4.41061H146.679V0.821324H1.91077V4.41061Z" fill="#D4B996" />
            </svg>
        </div>

                <div className="container_div mt-5 barreRecherche-page" style={{ width: '100vw' }}>
                    <div className="" style={{ display: 'flex', marginLeft: '-3%', alignItems: "center" }}>
                        <div className="" style={{ width: '65vw' }}>
                            <div className="position-relative">
                                <i className="fa fa-search fa-2x position-absolute" style={{ color: "#C5C5C5", top: '50%', fontSize: "1.4rem", transform: 'translateY(-50%)', left: '10px' }} />
                                <input type="text" onChange={handleSearch} className="form-control form-input pl-4" placeholder="Recherch un produit, ref .." />
                                <i className="bi bi-sliders fa-2x position-absolute" style={{ fontSize: "1.4rem", color: "#C5C5C5", top: '50%', transform: 'translateY(-50%)', right: '10px' }} />
                            </div>
                        </div>
                    </div>
                </div>

                
                <br />


<div className="liste-page" style={{display: 'flex', gap: '70px'}}> 

        <div className="input-group mb-3" style={{ width: '200px' , marginLeft:'70px'}}>
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ background: '#FFC400' , color:'#000'}}> Prix </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                <li className="liste-deroulante">
                <div className="input-group" style={{ width: '17vw', marginTop:'20px' , marginRight:'10px'}}>
                Min &nbsp;&nbsp;&nbsp;
                    <input type="text" className="form-control" style={{ marginTop:'-5px'}}/>
                </div>
                </li>

                <li className="liste-deroulante">
                <div className="input-group" style={{ width: '17vw', marginTop:'20px' , marginRight:'10px'}}>
                Max &nbsp;&nbsp;&nbsp;
                    <input type="text" className="form-control" style={{ marginTop:'-5px'}}/>
                </div>
                <div>
                    <button className="envoye" style={{ marginLeft:'90px', color:'black' }}>Applique</button>
                </div>  
                </li>
            </ul>
    </div> 

    <div className="input-group mb-3" style={{ width:'50vw'}}>
  <label className="input-group-text" htmlFor="inputGroupSelect01" style={{ background: '#FFC400' , color:'#000'}}>Distance</label>
  <select className="form-select" id="inputGroupSelect01">
  <option value="1"> <input type="text" name="text" id="text" />  </option>
    <option value="2"><i className="bi bi-geo-alt"></i>Activez votre localisation</option>
  </select>
</div>
<div className="input-group mb-3" style={{ width:'50vw'}}>
  <label className="input-group-text" htmlFor="inputGroupSelect01" style={{ background: '#FFC400' , color:'#000'}}>Date</label>
  <select className="form-select" id="inputGroupSelect01">
  <option value="1"> <input type="text" name="text" id="text" />  </option>
    <option value="2">Ce jour</option>
    <option value="2">Cette semaine</option>
    <option value="2">Ce mois</option>
    <option value="2">Cette année</option>
  </select>

</div>

                    <div>
                    <button className="btn btn-secondary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ background: '#FFC400' , color:'#000', width:'150px'}}> Applique </button>
                    </div>
  </div>
            <br /> 

            <div className="containerVisiteur-page">
  {currentRecords.length > 0 ? (
    currentRecords.map((pro) => (
      <div className="card_slid-page" key={pro.id}>
        <img src={imgProduit} alt="Image" />
        <div className="box-visiteur">
          <p>À 10 km de chez vous</p>
        </div>
        <div className="name-produit-visiteur">
          <p>{pro.Designation}</p>

          <svg className="etoile-visiteur" width="63" height="14" viewBox="0 0 63 14" fill="none" xmlns="http://www.w3.org/2000/svg" >
          </svg>
          <h5>{pro.PrixVenteArticleTTC} MAD</h5>

          <div className="brand">
            <img style={{ height: '30px' }} src={logoBrand} alt="" />
            <svg className="panier-box-visiteur" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            </svg>
          </div>
        </div>
      </div>
    ))
  ) : product && product.length > 0 ?(
    <div className="no-produit" style={{marginLeft:'30%'}}>
     <i className="bi bi-emoji-neutral"></i><br />
    <p>Malheureusement, on n‘a pas ce produit pour l’instant.</p><br />
  </div>
        ) : (
            <div className="no-produit">
                <i className="bi bi-info-lg"></i> Aucun produit
            </div>
        )}
        
        </div>;

 {pageNumbers.length > 1 && (
        <nav>
  <ul className="pagination">
    <li className="page-item">
      <button className="page-link" style={{ color: "#FFC400", backgroundColor: "transparent"}} onClick={() => handleChangePage(currentPage - 1)} disabled={currentPage === 1}>Précédent</button>
    </li>
    {pageNumbers.map((number) => (
      <li className="page-item" key={number}>
        <button className={`page-link ${currentPage === number ? 'active-button' : ''} page-number`} onClick={() => handleChangePage(number)}>{number}</button>
      </li>
    ))}
    <li className="page-item">
      <button className="page-link" style={{ color: "#FFC400", backgroundColor: "transparent"}} onClick={() => handleChangePage(currentPage + 1)} disabled={currentPage === Math.ceil(filteredProducts.length / recordsPerPage)}>Suivant</button>
    </li>
  </ul>
</nav>
        )}
    


            </div>
        </>
    );
}



