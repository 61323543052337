import { Link } from "react-router-dom";
import Sidebare from "../Sidbare/Sidebare";
import { ArticleInfo } from "../../../Modeles/ArticleModel";

const CreateMagasin:React.FC<ArticleInfo> = ({
  messageError,
  email,
  setemail,
  message,
  NomMagasin,
  tele,
  adresse,
  fax,
  Nom_complet_propriétaire,
  image,
  setMessageError,
  setMessage,
  setNomMagasin,
  settele,
  setadresse,
  setfax,
  setNom_complet_propriétaire,
  setImage,
  handleImage,
  handleSubmit,

  }) => {
  return <>    
    <Sidebare/>
    <div className="container mt-5">
    <div className="barRetour">
      <span className="iconRetour">
        <Link to="/magasin"><i className="bi bi-arrow-left-short"></i></Link>
        
      </span>
  </div>
<div className="marque">

<h4 className="">Créer un nouveau magasin</h4>
</div>
{
  messageError !=""?
<div className="alert alert-danger" role="alert">
  {messageError}
</div>
:""}
{
  message !=""?
<div className="alert alert-success" role="alert">
  {message}
</div>
:""}
<form onSubmit={handleSubmit}>
<div className="row" style={{marginTop:"-50px"}}>
<div className="col inputs">
    <div className="col-6">
        <span>Nom de magasin</span>
    <input onChange={(e)=>setNomMagasin(e.target.value)} type="text" className="form-control mb-3"  id="nom" />
  </div>
  <div className="col-6">
        <span>Nom complet de proprietaire</span>
    <input onChange={(e)=>setNom_complet_propriétaire(e.target.value)} type="text" className="form-control mb-3" id="nom" />
  </div>
  <div className="col-6">
        <span>E-mail</span>
    <input onChange={(e)=>setemail(e.target.value)} type="text" className="form-control mb-3" id="nom" />
  </div>
  <div className="col-6">
        <span>Numéro de téléphone</span>
    <input onChange={(e)=>settele(e.target.value)} type="text" className="form-control mb-3"  id="nom" />
  </div>
  <div className="col-6 hero ">
        <span>Localisation</span>
    <input onChange={(e)=>setadresse(e.target.value)} type="text" className="form-control mb-3"  id="nom"/>
  </div>
    </div>
   <div className="col">
            <div className="textUp text-center">
            <span className="">Logo de magasin</span>
            </div><br />
        <input onChange={handleImage} type="file" name="" id="ImportPhoto" />
        <label className="photoIploade" htmlFor="ImportPhoto" ><span><i className="bi bi-images"></i></span> <br /> Resolution minimal <br /> 250px * 250px </label>    
        <br /><button type="submit"><a>Confirmer</a></button>
    </div>
    </div>
</form>

    </div>

    </>
}
export default CreateMagasin;