import axios, { AxiosError } from "axios";
import { ChangeEvent, FormEvent, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateMagasin from "../../Views/Admin/magasin/CreateMagasin";

export default function AddMagasin(){
    const navigate = useNavigate();
    const [messageError, setMessageError] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [NomMagasin, setNomMagasin] = useState<string>("");
    const [tele, settele] = useState<string>("");
    const [adresse, setadresse] = useState<string>("");
    const [email, setemail] = useState<string>("");
    const [fax, setfax] = useState<string>("");
    const [Nom_complet_propriétaire, setNom_complet_propriétaire] = useState<string>("");
    const [image, setImage] = useState<File | null>(null);
    const handleImage = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setImage(e.target.files[0]);
      }
    };
  
    const handleSubmit = async (e: FormEvent) => {
      const MagasinId = localStorage.getItem('MagasinId');  
      e.preventDefault();
      if (
        NomMagasin.trim() === "" || 
        adresse.trim() === "" || 
        tele.trim() === "" || 
        Nom_complet_propriétaire.trim() === ""
      ) {
        setMessageError('Veuillez saisir toutes les informations');
        setTimeout(() => {
          setMessageError(''); // Clear the error message after 3 seconds
        }, 3000);
        return; // Exit early if any required field is empty
      }
      const formData = new FormData();
      formData.append("NomMagasin", NomMagasin);
      formData.append("Tele", tele);  
      formData.append("Adresse", adresse);  
      formData.append("Fax", fax);  
      formData.append("email", email);  
      formData.append("Nom_complet_propriétaire", Nom_complet_propriétaire);  
      if (image) {
        formData.append("image", image);
      }
  
      try {
        await axios.post(`${process.env.REACT_APP_PHP_APP_URL}/magasin/add`, formData)
        .then(({data})=>{
            console.log(data);
            setMessage("magasin ajouter avec sucesé");
            const timeoutId = setTimeout(() => {
                navigate("/magasin");
            }, 2000);
        });
      } catch (error: AxiosError | any) {
        
        
          setMessageError('Veuillez saisir toutes les informations')
          const timeoutId = setTimeout(() => {
          setMessageError('')
          }, 3000);
  
        } 
        
      }
      return<>
      <CreateMagasin 
              handleSubmit={handleSubmit}
              email={email}
              setemail={setemail}
              messageError={messageError}
              message={message}
              NomMagasin={NomMagasin}
              tele={tele}
              adresse={adresse}
              fax={fax}
              Nom_complet_propriétaire={Nom_complet_propriétaire}
              image={image}
              setMessageError={setMessageError}
              setMessage={setMessage}
              setNomMagasin={setNomMagasin}
              settele={settele}
              setadresse={setadresse}
              setfax={setfax}
              setNom_complet_propriétaire={setNom_complet_propriétaire}
              setImage={setImage}
              handleImage={handleImage} Designation={""} PrixVenteArticleTTC={""} Description={""} RefArticle={""} Unite={""} setDesignation={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setNomClient={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setAdresse={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setTotalCommandeHT={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setTotalRemise={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setPrixVenteArticleTTC={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setDescription={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setstock={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setRefARticle={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setUnite={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setprix_ht_2_magasin={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setprix_ht_3_magasin={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setprix_ht_1_magasin={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } } setquantité={function (value: SetStateAction<string>): void {
                  throw new Error("Function not implemented.");
              } }      />
      </>
    }

