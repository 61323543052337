import axios from "axios";
import { useParams } from "react-router-dom";

export default function AfficheCommercialMagasinInAdmin(id:any){
    const urlShowCommercial= `${process.env.REACT_APP_PHP_APP_URL}/admin/magasin/${id}/commercials`;
    const GetCommercial=()=>{
        return (axios.get(urlShowCommercial));
    }
    return {
        GetCommercial
    }
}