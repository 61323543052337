import React, { useEffect, useState } from 'react';
import Sidebare from "../Sidbare/Sidebare"
import PRODUIT1 from "../IMG/I-FLUX_200-768x768 1.png"
import profil from "../IMG/defaultProfil.png"
import "./CommercialInformation.css"
import { Link, useParams } from 'react-router-dom';
import GetGommercialById from '../../../Services/Admin/GetCommercialById';
import { CommercialInfo } from '../../../Modeles/Commercial';
import AfficheArticleInMagasin from '../../../Services/Magasin/AfficheArticleInMagasin';
import { ArticleInfo } from '../../../Modeles/ArticleModel';
import { info } from 'console';
import AfficheArticlesCCInfo from '../../../Services/Magasin/AfficheArticlesCCInfo';
export interface CommandeType{

  Commercial : CommercialInfo[],
  messageErrosCommercial:string,
}
export interface ArticleType{
  Article : ArticleInfo[],
  messageErrosArticle:string,
}
export default function CommercialInformation(){
  const { id } = useParams();
  const { idMag } = useParams();

  // -------------------------- affiche commercial by id --------------------

  const [stateCommercial, setstateCommercial] = useState<CommandeType>({
    Commercial: [] as CommercialInfo[],
    messageErrosCommercial: "Accune commande",
  });
  useEffect(()=>{
    setstateCommercial({...stateCommercial })
      GetGommercialById(id,idMag).GetCommercial()
        .then((res)=>setstateCommercial({...stateCommercial  , Commercial:res.data})
  
        )
        .catch(msg=>setstateCommercial({...stateCommercial  , messageErrosCommercial:msg.messageErrosCommercial}))
  },[]);
  const {Commercial , messageErrosCommercial} = stateCommercial

  // -----------------------------  affiche article -------------------------------
  const [stateArticle, setstateArticle] = useState<ArticleType>({
    Article: [] as ArticleInfo[],
    messageErrosArticle: "Accune commande",
  });
  useEffect(()=>{
    setstateArticle({...stateArticle })
    AfficheArticlesCCInfo(id).getArticle()
        .then((res)=>setstateArticle({...stateArticle  , Article:res.data})
  
        )
        .catch(msg=>setstateArticle({...stateArticle  , messageErrosArticle:msg.messageErrosArticle}))
  },[]);
  const {Article , messageErrosArticle} = stateArticle
  const handleGoBack = () => {
    window.history.back();
};
console.log(Article);
    return <>

    <Sidebare/>
    <div className="container mt-3">
          <div className="barRetour">
        <span className="iconRetour">
          <i onClick={handleGoBack} className="bi bi-arrow-left-short"></i>
        </span>
      </div>
      </div>
      <div className="container-commercial-detail">
      <div className="card-commercial-detail ">
      <div className="photo-info-commercial-detail">
          <img src={profil} />
      </div>
  {Commercial && Commercial.length>0?Commercial.map(com=>(

      <h2 className="product-name-commercial-detail">{com.Nom} {com.prenom}</h2>
    )):""
  }

    <h6 >Statut : <span>Actif <i className="actif bi bi-circle-fill"></i></span> </h6>
    <div className="sold text-center">
        <div className="progresse">
          
            <div className="progress" style={{ width: '50%', backgroundColor: '#FFC400' }}>
            </div>
            <div className="solde">
  {Commercial && Commercial.length>0?Commercial.map(com=>(
              <h5>Solde de {com.Nom} </h5>
            )):""
          }

            </div>
              <span> 2 500 MAD /  10 000 MAD</span>
            
          </div>
      </div>
    <Link className='mt-3 bloque' to={""}><h6 className="mb-5 ">bloqué <i className="bi bi-dash-circle"></i> </h6></Link>

  </div>
  {Commercial && Commercial.length>0?Commercial.map(com=>(
  <div className="card2-commercial-detail">
      <div className="info-commercial-detail">
        
          <p><strong>Nom:</strong></p>  <span>{com.Nom} {com.prenom}</span>
          <hr />
          <p><strong>Email:</strong> </p> <span>{com.email}</span>
          <hr />
          <p><strong>Mot de passe:</strong></p>  <span>{com.password}</span>
          <hr />
          <p><strong>Mobile:</strong></p>  <span>{com.télephone  ?com.télephone :"N/A"}</span>
          <hr />
          <p><strong>Adress:</strong></p><span> {com.ville ?com.ville :"N/A"}</span>    
      </div>
  </div>
  )):""
  }
  <div className="card3-commercial-detail" >
      {/*  produi1 */}
    {Article && Article.length>0? Article.map(art=>(

      <div className='product-card-commercial-detail mb-3' style={{ marginTop: '30px' }}>
    <div className='produit-commercial-detail'>
    <div className="photo-commercial-detail" style={{ marginTop: '10px' }}>
              <img src={PRODUIT1} width= "100px" alt="Produit" />
          </div>
    <span className="card-title-commercial-detail">{art.Des}</span>
      </div>
      <div className='vente-commercial-detail'>
    <br />
    <div className="card-text-commercial-detail"  style={{ marginTop: '40px' }}>
    <div className="progress-container-commercial-detail">
    <div className="progress-item-commercial-detail">
        
        <div className="progress mb-2" style={{ width: '170px' }} role="progressbar" aria-label="Basic example">
          <div className="progress-bar" style={{ width: '45%', backgroundColor: 'green' }}></div>
        </div>
        +22confirmé
      </div>

      <div className="progress-item-commercial-detail">
        <div className="progress mb-2" style={{ width: '170px' }} role="progressbar" aria-label="Basic example">
          <div className="progress-bar" style={{ width: '10%', backgroundColor: '#c9b719' }}></div>
        </div>
        +7remboursé
      </div>

      <div className="progress-item-commercial-detail">
        <div className="progress mb-2" style={{ width: '170px' }} role="progressbar" aria-label="Basic example">
          <div className="progress-bar" style={{ width: '25%', backgroundColor: 'red' }}></div>
        </div>
        +15 Annuler
      </div>
    </div>
  </div> 
  </div>
  </div>
    )):""
    }
  <button className='btn btn-danger mt-5'>Supprimer ce client </button>
  </div>

</div>   

    </>
}